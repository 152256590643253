import {useQuery} from "react-query";
import axios from "axios";


const apiUrl = "/api/authorities";



const fetchAuthorities = async () => {
    const response = await axios.get(`${apiUrl}/find-all-for-notification-group`);
    return response.data;
};



export const AuthorityReactQuery = () => {
    return useQuery("authorities", fetchAuthorities);
}