import {AppBar, Box, Button, Tooltip, useTheme} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {
    DeleteTeleObjectTypeReactQuery,
    TeleObjectTypeReactQuery
} from "./TeleObjectTypeReactQuery";
import {Link, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import IconButton from "@mui/material/IconButton";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import {QueryClient, QueryClientProvider} from "react-query";
import Toolbar from "@mui/material/Toolbar";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

export const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'id', condition: 'equals', value: '', sort: ''}
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'name', condition: 'contains', value: '', sort: 'asc'}
    },
] as ADataGridColumn[]

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowTeleObjectType {
    id?: number;
    name: string;
    actionsConfig?: Array<ActionConfig>
}

export const TeleObjectTypeSettingsPage = () =>{
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_TELE_OBJECT_TYPE
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_TELE_OBJECT_TYPE
    ]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const { data, isSuccess } = TeleObjectTypeReactQuery(pagedSearchParams);
    const [rows, setRows] = useState<Array<RowTeleObjectType>>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);



    const navigate = useNavigate();
    const mutation = DeleteTeleObjectTypeReactQuery(pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);


    useEffect(() => {
        if(isSuccess){
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsTeleObject = data.data.map((teleObject: any) => {
                    return {
                        id: teleObject.id,
                        name: teleObject.name,
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });

                setRows(rowsTeleObject);
            }
        }
    }, [data?.data]);


    const handleEditTeleObjectType = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_TELE_OBJECT_TYPE_FORM, { state: { id: data.id } });
    },[navigate]);

    const handleDeleteTeleObjectType = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        mutation.mutate({ id: selectedToDelete, pagedSearchParams });
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    useEffect(() => {
        if(mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
        }

    }, [mutation.isSuccess]);

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const theme = useTheme();
    return(
        <>
            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <div style={{marginLeft: '22%'}}>
                        <Button
                            size={"small"}
                            variant="contained"
                            component={Link}
                            disabled={!isAuthorizedToSave}
                            to={SystemRoutes.SETTINGS_TELE_OBJECT_TYPE_FORM}
                            endIcon={<AddCircle/>}
                        >
                            Novo
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>

            <Box marginTop={10}>
                <ACard>
                    <ADataGrid
                        columns={initColumns}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit" disabled={!isAuthorizedToSave} onClick={(value) => handleEditTeleObjectType(value)}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete" disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteTeleObjectType(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete />
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar esse tipo de Tele Objeto?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>

        </>
    );
}

export default function TeleObjectTypeHomePage(){
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <TeleObjectTypeSettingsPage />
        </QueryClientProvider>
    )
}
