import {Box, Button, Paper, useTheme} from "@mui/material";
import {clearCountryEntity, clearCountryMessages, deleteEntity, reset, searchEntities} from './CountryReducer';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {useNavigate} from "react-router-dom";
import {Delete as DeleteIcon, Edit} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import {ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from '@atiautomacao/ati-ui-library';
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import CountryHeaderMenu from "./CountryHeaderMenu";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

const columns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        minWidth: 100,
        breakpoints: {
            xs: false,
            sm: false,
            md: false,
            lg: true,
            xl: true
        },
        filter: {name: 'id', condition: 'contains', value: '', sort: 'desc'}
    },
    {
        name: 'name',
        label: 'name',
        align: 'left',
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
] as ADataGridColumn [];

export default function CountryPage(){
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [entityToDelete, setEntityToDelete] = useState({id: null});
    const [openDialog, setOpenDialog] = useState(false);

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_COUNTRY
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_COUNTRY
    ]);
    const isLoading = useAppSelector((state : RootState) => state.country.loading);
    const errorMessage = useAppSelector((state : RootState) => state.country.errorMessage);
    const successMessage = useAppSelector((state : RootState) => state.country.successMessage);
    const entities = useAppSelector((state : RootState) => state.country.entities);
    const totalOfRecords = useAppSelector((state : RootState) => state.country.totalOfRecords);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columns), 0, 10));

    const [rows, setRows] = useState<Array<any>>([]);

    useEffect(() => {
        let newRows = entities.map(et => {return {id: et.id, name: et.name, actionsConfig: [
                {actionName: "edit", disabled: !isAuthorizedToSave},
                {actionName: "delete", disabled: !isAuthorizedToDelete},
            ]}});
        setRows(newRows)
    }, [entities]);

    const handleOnRowEdit = useCallback((data: any) => {
        dispatch(reset());
        navigate(`/settings/country/${data.id}`);
    },[]);

    const handleOnRowDelete = useCallback((data: any) => {
        setEntityToDelete(data);
        setOpenDialog(true);
    },[]);

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogConfirm = () => {
        //TODO
        // @ts-ignore
        dispatch(deleteEntity(entityToDelete.id)).then((success => {
                if(pagedSearchParams.page > 1 && totalOfRecords - 1 <= pagedSearchParams.size){
                    setPagedSearchParams( {
                        ...pagedSearchParams,
                        page: pagedSearchParams.page - 1

                    })
                }else{
                    if (!isLoading) {
                        dispatch(searchEntities(pagedSearchParams));
                    }
                }

                handleDialogToggle();
            }
        )).catch(error => {});
    }

    const handleDialogClose = () => {
        // @ts-ignore
        setEntityToDelete({});
        handleDialogToggle();
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        // console.log(`CountryPage.tsx - handleOnFiltersChange()`, dataGridFilters);
        let newPageParams :PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    },[]);

    const handleOnRowSelect = useCallback((rows : any) => {
        // console.log(`CountryPage.tsx - handleOnRowSelect()`, rows);
    },[]);

    // Component Did Mount
    useEffect(() => {
        // console.log(`CountryPage.tsx - did mount`);
        dispatch(clearCountryEntity());
        if (!isLoading) {
            dispatch(searchEntities(pagedSearchParams));
        }
    },[]);

    const paramsRef = useRef(false);
    useEffect(() => {
        if(paramsRef.current){
            // console.log(`CountryPage.tsx - pagedSearchParams - effect`);
            if (!isLoading) {
                dispatch(searchEntities(pagedSearchParams));
            }
        }else {
            paramsRef.current = true;
        }
    },[pagedSearchParams]);

    const entitiesRef = useRef(false);
    useEffect(() => {
        console.log("COMP ENTITIES ", entities);
        if(entitiesRef.current){
            // console.log(`CountryPage.tsx - entities effect`, entities);
        }else {
            entitiesRef.current = true;
        }
    },[entities]);

    const columnsRef = useRef(false);
    useEffect(() => {
        if(columnsRef.current){
            // console.log(`CountryPage.tsx - columns effect`, columns);
        }else {
            columnsRef.current = true;
        }
    },[columns]);

    // On updateSuccess change
    useEffect(() => {
        if (successMessage) {
            enqueueSnackbar(successMessage, {
                variant: 'success',
            });
            dispatch(clearCountryMessages());
        }
        if(errorMessage) {
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
            dispatch(clearCountryMessages());
        }
    }, [errorMessage, successMessage]);

    // console.log("CountryPage.tsx - render");
    return (<>
            <CountryHeaderMenu />
            <Paper elevation={3}>
                <Box p={2} style={{backgroundColor: "white"}}>
                    <ADataGrid
                        hideFilters={true}
                        showFilterOptions={false}
                        hideSelection={false}
                        columns={columns}
                        rows={rows}
                        page={pagedSearchParams.page}
                        loading={false}
                        totalOfRecords={totalOfRecords? totalOfRecords : 0}
                        rowsPerPage={pagedSearchParams.size}
                        onFiltersChange={handleOnFiltersChange}
                        onRowSelect={handleOnRowSelect}
                        actions={
                            <>
                                <Button
                                    name="edit"
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    disabled={!isAuthorizedToSave}
                                    onClick={handleOnRowEdit}
                                >
                                    <Edit />
                                </Button>
                                <Button
                                    name="delete"
                                    variant="contained"
                                    size="small"
                                    sx={{ml:2}}
                                    color="info"
                                    disabled={!isAuthorizedToDelete}
                                    onClick={handleOnRowDelete}
                                >
                                    <DeleteIcon />
                                </Button>
                            </>
                        }
                    />
                    <ConfirmDialog
                        title={'Delete confirmation'}
                        description={'Do you want to confirm ?'}
                        open={openDialog}
                        handleConfirm={handleDialogConfirm}
                        handleClose={handleDialogClose}
                    />
                </Box>
            </Paper>

        </>
    );
}
