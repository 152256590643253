import { ADataGridColumn } from "@atiautomacao/ati-ui-library";

const commonBreakpoints = {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true
};

const idBreakpoints = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false
};

export const columnsData: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        minWidth: 100,
        visibility: false,
        breakpoints: idBreakpoints
    },
    {
        name: 'domainDisplayName',
        label: 'FUNCIONALIDADE',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: commonBreakpoints
    },
    {
        name: 'displayName',
        label: 'PERMISSÃO',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: commonBreakpoints
    }
];