import MenuReportReactQuery from "../Reports/TeleObjectsReport/types/reactQuery/MenuReportReactQuery";
import * as React from "react";
import {NavigationMenu} from "@atiautomacao/ati-ui-library";

interface TeleCommandMainMenuProps {
    onSelectedIdsChange: Function;
}

export const TeleCommandMainMenu = ({onSelectedIdsChange}: TeleCommandMainMenuProps) => {

    const { data } = MenuReportReactQuery();


    let dataMenu = data?.data;

    const handleNodeSelected = (nodes: any) => {
        onSelectedIdsChange(nodes)
    };

    return (
        <React.Fragment>
            {
                dataMenu !== undefined && dataMenu.length > 0 &&
                <NavigationMenu data={dataMenu} onNodeClick={handleNodeSelected}/>
            }
        </React.Fragment>
    );
}