import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {
    Container,
    TextField,
    InputLabel,
    FormHelperText, Select, MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
    FindAllPowerStationReactQuery,
    FindBrokerByIdReactQuery,
    SaveBrokerReactQuery
} from "./BrokerReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import {PowerStationProps} from "../../../Shared/Types/PowerStation";
import {MenuProps} from "../../../Utils/PropsStyles";
import {BrokerProps} from "../../../Shared/Types/Broker";
import {useSnackbar} from "notistack";

export const BrokerFormPage = () => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const id = location.state?.id as string;
    const navigate = useNavigate();

    const mutationSave = SaveBrokerReactQuery();
    const mutationFindById = FindBrokerByIdReactQuery();
    const mutationFindAllPowerStation = FindAllPowerStationReactQuery();

    const [brokerName, setBrokerName] = useState<string>("");
    const [ipAddress, setIpAddress] = useState<string>("");
    const [port, setPort] = useState<number>(NaN);
    const [txQueue, setTxQueue] = useState<string>("");
    const [rxQueue, setRxQueue] = useState<string>("");
    const [notificationQueue, setNotificationQueue] = useState<string>("");
    const [powerStation, setPowerStation] = useState<number>(-1);
    const [powerStationList, setPowerStationList] = useState<PowerStationProps[]>([]);


    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let broker = data?.data as BrokerProps;
                    setBrokerName(broker.name);
                    setIpAddress(broker.ipAddress);
                    setPort(broker.port);
                    setTxQueue(broker.txQueue);
                    setRxQueue(broker.rxQueue);
                    setNotificationQueue(broker.notificationQueue)
                    setPowerStation(broker.powerStation.id);
                }
            });
        }
    }, []);

    useEffect(() => {
        setPowerStationList(mutationFindAllPowerStation.data ?? [])
    }, [mutationFindAllPowerStation.data]);

    const validateBrokerData = (data:BrokerProps) => {
        const requiredFields:Array<keyof BrokerProps> = [
            'name',
            'ipAddress',
            'port',
            'txQueue',
            'rxQueue',
            'notificationQueue',
            'powerStation'
        ];

        for (const field of requiredFields) {
            if (data[field] === null || data[field] === undefined || data[field] === '') {
                enqueueSnackbar(`Preencha os campos obrigatórios!`, { variant: "error" });
                return false
            }
        }
        return true
    }

    const handleSave = () => {
        const newPoweStation = powerStationList.find((entity) => entity.id == powerStation);
        if(newPoweStation != null && port != null){
            let newBroker: BrokerProps = {
                id: id != null ? Number(id) : null,
                name: brokerName,
                ipAddress: ipAddress,
                port: port,
                txQueue: txQueue,
                rxQueue: rxQueue,
                notificationQueue: notificationQueue,
                powerStation: newPoweStation,
                isSelected: false

            }
            if(validateBrokerData(newBroker)){
                mutationSave.mutate(newBroker, {
                    onSuccess: () => {
                        navigate(SystemRoutes.SETTINGS_BROKER);
                    }
                });
            }
        }else{
            enqueueSnackbar(`Preencha os campos obrigatórios!`, { variant: "error" });
        }

    }

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_BROKER}/>
            <Box style={{paddingTop: 64}}>
                <ACard>
                    <Container style={{
                        marginLeft: -10,
                        display: 'flex',
                        flexDirection: window.innerWidth <= 900 ? "column": "row",
                        alignItems: 'left',
                        justifyContent: 'left',
                        columnGap: 30
                    }}>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="type-name">Nome</InputLabel>
                                <TextField
                                    error={brokerName === ""}
                                    fullWidth
                                    id="type-name"
                                    variant="standard"
                                    placeholder={"Nome do broker"}
                                    onChange={(value) => setBrokerName(value.target.value)}
                                    value={brokerName}
                                />
                                {
                                    brokerName === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="ipAddress">Endereço IP</InputLabel>
                                <TextField
                                    fullWidth
                                    error={ipAddress === ""}
                                    id="ipAddress"
                                    variant="standard"
                                    placeholder={"Endereço IP"}
                                    onChange={(value) => setIpAddress(value.target.value)}
                                    value={ipAddress}
                                />
                                {
                                    ipAddress === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="port">Porta</InputLabel>
                                <TextField
                                    fullWidth
                                    error={isNaN(port)}
                                    id="port"
                                    variant="standard"
                                    placeholder={"Digite a porta"}
                                    type="number"
                                    onChange={(value) => setPort(parseInt(value.target.value))}
                                    value={port}
                                />
                                {
                                    isNaN(port) &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="txQueue">Fila TX</InputLabel>
                                <TextField
                                    fullWidth
                                    error={txQueue === ""}
                                    id="txQueue"
                                    variant="standard"
                                    placeholder={"Digite a fila TX"}
                                    onChange={(value) => setTxQueue(value.target.value)}
                                    value={txQueue}
                                />
                                {
                                    txQueue === '' &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="rxQueue">Fila RX</InputLabel>
                                <TextField
                                    fullWidth
                                    error={rxQueue === ""}
                                    id="rxQueue"
                                    variant="standard"
                                    placeholder={"Digite a fila RX"}
                                    onChange={(value) => setRxQueue(value.target.value)}
                                    value={rxQueue}
                                />
                                {
                                    rxQueue === '' &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="notificationQueue">Fila de notificação</InputLabel>
                                <TextField
                                    fullWidth
                                    error={notificationQueue === ""}
                                    id="notificationQueue"
                                    variant="standard"
                                    placeholder={"Digite a fila de notificação"}
                                    onChange={(value) => setNotificationQueue(value.target.value)}
                                    value={notificationQueue}
                                />
                                {
                                    notificationQueue === '' &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </div>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="selectPowerStation">Usina</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="selectPowerStation"
                                    id="selectPowerStation"
                                    placeholder={"Selecione a usina"}
                                    value={powerStation}
                                    label="Tipo"
                                    onChange={(value) => setPowerStation(value.target.value as number)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        powerStationList.map((entity,index) => {
                                            return (
                                                <MenuItem key={index.valueOf()} value={entity.id}>{entity.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                { powerStation == -1 &&
                                    <FormHelperText id="my-helper-text-power-station">Campo Obrigatório.</FormHelperText>

                                }
                            </Box>
                        </div>
                    </Container>
                </ACard>
            </Box>
        </>
    )
}

export function BrokerHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <BrokerFormPage/>
        </QueryClientProvider>
    )
}