import * as React from "react";
import {FunctionComponent, useCallback, useState} from "react";
import {AppBar, Button, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {Link, useNavigate} from "react-router-dom";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {useAppSelector} from "../../Config/Hooks";
import ConfirmDialog from "../../Shared/Components/ConfirmDialog";

import {SystemRoutes} from "../../Utils/RouteUtils";
import SaveIcon from "@mui/icons-material/Save";


interface Props {
    analysisId?: any,
    isConfig?: boolean,
    onAnalysisPressSave?: any,
    disableSave?: boolean
    disableDelete?: boolean
}

 const AnalysisHeaderMenu: FunctionComponent<Props> = ({analysisId, isConfig, onAnalysisPressSave, disableSave = false, disableDelete = false}: Props) => {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);

    const handleOnRowDelete = useCallback((data: any) => {
        setOpenDialog(true);
    },[]);
    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }
    const handleDialogClose = () => {
        handleDialogToggle();
    }
    const handleDialogConfirm = async () => {
        navigate(SystemRoutes.ANALYSIS, {state: {reload: analysisId}});
        handleDialogToggle();
    }

    const handleSaveAnalyses = () => {
        onAnalysisPressSave()
    }
     const theme = useTheme();
    if (isAuthenticated ) return (
        <AppBar style={{top: 64}}>
            <Toolbar
                sx={{
                    marginLeft: {xs: "initial", md: isAuthenticated ? (
                            openSubMenu ? (
                                openMainMenu ? `${400}px` : `${80}px`
                            ) : (
                                openMainMenu ? `${80}px` : `${0}` // Set margin to 50 if submenu closed and main menu open
                            )
                        ) : 'initial'},
                    backgroundColor: theme.palette.background.default
                }}
            >
                {
                    !isConfig  &&
                    <Button
                        disabled={disableSave}
                        size={"small"}
                        variant="contained" component={Link} style={{marginRight: 12}}
                        sx={{textTransform: 'capitalize', marginRight: 1}}
                        to={`${SystemRoutes.ANALYSIS_CONFIG}/new`} endIcon={<AddCircle />}
                    >
                        Novo
                    </Button>
                }

                    {
                        (analysisId || isConfig)  &&
                        <>
                            {  !isConfig  &&
                                <Button
                                    disabled={disableSave}
                                    size={"small"}
                                    variant="contained" component={Link} style={{marginRight: 12}}
                                    sx={{textTransform: 'capitalize', marginRight: 1}}
                                    to={`${SystemRoutes.ANALYSIS_CONFIG}/${analysisId}`}
                                    endIcon={<AddCircle/>}
                                >
                                    Editar
                                </Button>
                            }
                            { isConfig  &&
                                   <Button
                                       variant="contained"
                                       size={"small"}
                                       color={"primary"} style={{marginRight: 12}}
                                       endIcon={<SaveIcon/>}
                                       onClick={handleSaveAnalyses}
                                       sx={{textTransform: 'capitalize', marginRight: 1}}
                                   >
                                       Salvar
                                   </Button>
                            }
                                   <Button
                                       size={"small"}
                                       variant="contained" style={{marginRight: 12}}
                                       endIcon={<RemoveCircle/>}
                                       disabled={disableDelete}
                                       onClick={handleOnRowDelete}
                                       sx={{textTransform: 'capitalize', marginRight: 1}}
                                   >
                                       Deletar
                                   </Button>
                        </>
                    }

            </Toolbar>

            <ConfirmDialog
                title={'Deletar'}
                description={'Deseja deletar essa análise?'}
                open={openDialog}
                handleConfirm={handleDialogConfirm}
                handleClose={handleDialogClose}
            />
        </AppBar>
    );

    return null;
}

export default AnalysisHeaderMenu