import React, {useState} from "react";
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tabs} from "@mui/material";
import {GenerationPRChart} from "../GenerationPRChart/GenerationPR";
import {GenerationPRChartComparison} from "../GenerationPRChartComparison/GenerationPRComparisonChart";
import {
    ACard,
    FloatMenuItem,
    APopoverIcon,
    Period,
    PeriodSelector,
    PeriodSelectorGroupBy, ACardSplitFooterActions
} from "@atiautomacao/ati-ui-library";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faExpand,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faGear,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {addDays} from "date-fns";
import GenerationYield from "../GenerationYieldChart/GenerationYield";
import GenerationYieldComparison from "../GenerationYieldComparisonChart/GenerationYieldComparison";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, m: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
export default function PRComparison() {
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByPdf = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_PDF_EXPORT, AUTHORITIES.EXPORT_PR, AUTHORITIES.PDF_EXPORT_PR
    ]);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_PR, AUTHORITIES.CSV_EXPORT_PR
    ]);
    const isAuthorizedToSearchByExcel = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_EXCEL_EXPORT, AUTHORITIES.EXPORT_PR, AUTHORITIES.EXCEL_EXPORT_PR
    ]);
    const [prValue, setPrValue] = React.useState(0);

    const initialPRPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const initialPRComparisonPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const prGroupsInterval = {
        day: {date: new Date(), startTime: "00:00:00", endTime: "23:59:59"},
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
    };
    const prGroups : PeriodSelectorGroupBy[] = ["day", "week", "month", "year"];

    const prComparisonGroupsInterval={
        day: {date: new Date(), startTime: "00:00:00", endTime: "23:59:59"},
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
    };

    const prComparisonGroups: PeriodSelectorGroupBy[] = ["day", "week", "month", "year"];


    const [period, setPeriod] = useState<Period>(prValue === 0 ? initialPRPeriod : initialPRComparisonPeriod);


    const handleTabChange = (period: Period) => {
        if(prValue === 0){
            period.groupBy = initialPRPeriod.groupBy;
        }else {
            period.groupBy = initialPRComparisonPeriod.groupBy;
        }
        setPeriod(period);
    }

    const handleChangePR = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setPrValue(parseInt(value));
    };

    return (
        <ACard
            title="Performance Ratio"
            headerControlsPosition={"header"}
            headerControls={
                <>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        disableHourSelector={true}
                        inputFormat={'dd/MM/yyyy'}
                        hideGroup={false}
                        periodInterval={prValue === 0 ? prGroupsInterval : prComparisonGroupsInterval}
                        period={period}
                        onChange={handleTabChange}
                        groupByOptions={prValue === 0 ? prGroups : prComparisonGroups}
                    />
                </>
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //             disable={true}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //             disable={true}
            //         />
            //     </>
            // }
            styleProps={{
                contentStyle: {paddingTop: 0, margin: 0}
            }}
            footerActions={
               <ACardSplitFooterActions
                   leftActions={
                       <FormControl>
                           <RadioGroup
                               style={{marginLeft: 10}}
                               row
                               aria-labelledby="demo-row-radio-buttons-group-label"
                               name="controlled-radio-buttons-group"
                               value={prValue}
                               onChange={handleChangePR}
                           >
                               <FormControlLabel value={0} control={<Radio />} label="Comparativo" />
                               <FormControlLabel value={1} control={<Radio />} label="Absoluto" />
                           </RadioGroup>
                       </FormControl>
                   }
                   leftGridWidth={9}
                   rightActions={
                       <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                           <IconButton disabled>
                               <FontAwesomeIcon icon={faFilePdf} fontSize={20}/>
                           </IconButton>
                           <IconButton disabled>
                               <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                           </IconButton>
                           <IconButton disabled>
                               <FontAwesomeIcon icon={faFileExcel} fontSize={20}/>
                           </IconButton>
                       </APopoverIcon>
                   }
                   rightGridWidth={3}
               />
            }
        >
            { prValue === 0 ?
                    <GenerationPRChart period={period}/>
                :
                    <GenerationPRChartComparison period={period}/>
            }
        </ACard>
    )
}