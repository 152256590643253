import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";
import {EquipmentProps} from "../../../Shared/Types/Equipment";

const apiUrl = "api/equipment";
const apiUrlTeleObject = "api/teleobject";

const saveEquipment = async (data: EquipmentProps) => {
    try {
        return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const SaveEquipmentReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveEquipment(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Equipamento salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchEquipment = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const EquipmentSearchReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["page equipment", pagedSearchParams],
        queryFn: () => {
            return fetchEquipment(pagedSearchParams);
        }
    });
};

const fetchAllPowerStation = async () => {
    try {
        return await axios
            .get(`api/power-station/find-all`,{timeout:30000})
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindAllPowerStationToEquipmentReactQuery = () => {
    return useQuery({
        queryKey: ["data-find-all-power-station"],
        queryFn: () => {
            return fetchAllPowerStation();
        }
    });
};

const fetchEquipmentById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindEquipmentByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchEquipmentById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteEquipment = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const DeleteEquipmentReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteEquipment(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Equipamento excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]).then(r => r);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchEquipments = async (powerStationId: number, skidId?: number) => {
    try {
        // Construir a URL com base nos parâmetros
        const url = skidId
            ? `${apiUrl}/find-all-equipments/${powerStationId}?skidId=${skidId}`
            : `${apiUrl}/find-all-equipments/${powerStationId}`;

        // Fazer a requisição
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllEquipmentsBySkidNullOrNotReactQuery = (powerStationId: number | undefined, skidId?: number) => {
    return useQuery(
        ['find-all-equipments', powerStationId, skidId], // Key for caching and invalidation
        () => {
            if(powerStationId) {
                return fetchEquipments(powerStationId, skidId)
            }
            return [];
        },
    );
};

const fetchTeleObjects = async (equipmentId: number) => {
    try {
        // Construir a URL com base no parâmetro equipmentId
        const url = `${apiUrlTeleObject}/find-all/${equipmentId}`;

        // Fazer a requisição
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllTeleObjectsByEquipmentIdReactQuery = (equipmentId: number | undefined) => {
    return useQuery(
        ['find-all-tele-objects', equipmentId],
        // Key for caching and invalidation
        () => {
            if (equipmentId) {
                return fetchTeleObjects(equipmentId)
            }
            return [];
        }
    );
};
