import React, {FunctionComponent, useEffect, useState} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {Stack, TextField} from "@mui/material";

import {useAppSelector} from '../../../../Config/Hooks';
import {RootState} from '../../../../Config/Store';
import {SkidType} from './types/Skid';
import ChartHeatmap from './ChartHeatmap';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {startOfDay, endOfDay, isWithinInterval} from 'date-fns';
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {ACard, APopoverIcon, Skeleton, FloatMenuItem} from "@atiautomacao/ati-ui-library";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";

const PowerPUForInvertersChart: FunctionComponent<{ powerPlant: any; }> = ({powerPlant}) => {

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const {entities, isLoading} = useAppSelector((state: RootState) => state.dashboardNavigationMenu);

    const initListSkid = () => {
        const skidList = new Array<any>();
        if(entities){
            entities.filter((element) => element.id === powerPlant.id).map((item) =>
                item.skidList.forEach((skid) => skidList.push(skid))
            );
        }
        return skidList;
    }
    const [entitiesPUList, setEntitiesPUList] = useState<Array<SkidType>>(initListSkid());

    const [date, setDate] = useState<Date | null>(startOfDay(new Date()));

    useEffect(() => {
        setEntitiesPUList(initListSkid())
        setDate(startOfDay(new Date()))
    }, [powerPlant]);

    useInterval(() => {
        setEntitiesPUList(initListSkid())
    }, 60000, 2000)

    const [content, setContent] = useState<JSX.Element>()

    useEffect(() => {
        if(isLoading){
            setContent(<Skeleton animation="wave" height={289} variant="rounded" width={'100%'} />)
        }else if(entitiesPUList && entitiesPUList.length > 0){
            setContent(<ChartHeatmap skidList={entitiesPUList} date={date} />)
        }else{
            setContent(<DataNotFound boxStyle={{height: 289, width: '100%'}}/>)
        }
    }, [isLoading, date]);

    return (
        <ACard
            key={`chart-power-pu-${openSubMenu}`}
            // cardStyle={{height: 445}}
            styleProps={{
                contentStyle: {padding: 0, height: 400}
            }}
            title="Potência PU"
            headerControlsPosition={"subheader"}
            headerControls={
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction={"row"} spacing={0} style={{marginRight: 15}}>
                            <DatePicker
                                label="Selecione o dia"
                                renderInput={(params) => <TextField variant="standard" {...params} />}
                                value={date}
                                inputFormat={"DD/MM/YYYY"}
                                onChange={(newValue) => {
                                    if (newValue && isWithinInterval(new Date(newValue), { start: new Date('1980-01-01'), end: endOfDay(new Date()) })) {
                                        setDate(startOfDay(new Date(LocalDateTimeFormatISO(newValue))));
                                    }
                                    return;
                                }}
                            />
                        </Stack>
                    </LocalizationProvider>
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //         />
            //     </>
            // }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20} />}>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                    </IconButton>
                </APopoverIcon>
            }
        >
            <>
                {content != null && content}
            </>

        </ACard>
    )
}

export default PowerPUForInvertersChart;
