import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
interface ParamsModalProps {
    open: boolean;
    onCloseModal: any;
    commandDTOList: Array<CommandDTO>;
}
export interface CommandDTO {
    id: number;
    teleObjectName: string;
    userId: number;
    point: number;
    teleObjectSpecType: number;
    etsCode: number;
    brokerId: number;
    powerStationName?: string;
    skidName?: string | null;
    equipmentName?: string;
}
export const SendingTelecommandUnifilar = ({open, onCloseModal, commandDTOList}: ParamsModalProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [inputValue, setInputValue] = useState("");
    const [commandDTO, setCommandDTO] = useState<CommandDTO | null>(null)
    const cardInfo = commandDTOList != null && commandDTOList.length > 0 ? commandDTOList[0] : null

    useEffect(() => {
        setInputValue("")
    }, [onCloseModal]);

    const handleButtonClick = (event:any, data:CommandDTO) => {
        console.log(event)
        setCommandDTO(data)
    }

    const handleSend = async () => {
        if (commandDTO != null && inputValue) {
            if (!/^\d+$/.test(inputValue)) {
                enqueueSnackbar("Apenas valores numéricos são aceitos!", {variant: "error"});
                return;
            }
            axios.post('/api/send-command', {
                userId: commandDTO.userId,
                teleObjectId: commandDTO.id,
                point: commandDTO.point,
                teleObjectSpecType: commandDTO.teleObjectSpecType,
                etsCode: commandDTO.etsCode,
                data: Number(inputValue),
                brokerId: commandDTO.brokerId
            }).then(
                response => {
                    enqueueSnackbar("Comando enviado com sucesso!", { variant: "success" });
                    onCloseModal()
                }
            ).catch(
                error => {
                    enqueueSnackbar("Erro ao enviar o comando, tente novamente!", {variant: "error"});
                    console.error("Erro ao enviar comando. Tente novamente:", error);
                }
            )
        }
    };
    const printFullHeader = () => {
        if(cardInfo != null){
            if(cardInfo?.skidName != null && cardInfo?.powerStationName != null && cardInfo?.equipmentName != null){
                return true
            }
        }
        return false
    }
    return (
        <Dialog open={open} onClose={onCloseModal}>

            {printFullHeader() ?
                <DialogTitle>{cardInfo?.equipmentName + ' - ' + cardInfo?.skidName + ' - ' + cardInfo?.powerStationName}</DialogTitle>
                :   <DialogTitle>{cardInfo?.teleObjectName}</DialogTitle>
            }
            <DialogContent>
                <Autocomplete
                    id="grouped-demo"
                    value={commandDTO}
                    fullWidth
                    options={commandDTOList}
                    getOptionLabel={(option) => option.teleObjectName}
                    inputValue={commandDTO?.teleObjectName ?? ''}
                    renderInput={(params) => <TextField {...params} label={"Comandos"} variant="standard" />}
                    onChange={(event: any, newValue: any) => handleButtonClick(event,newValue)}
                    sx={{paddingBottom: 3}}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Digite o valor"
                    type="text"
                    fullWidth
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained" disabled={inputValue === ""}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}