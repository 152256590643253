import {QueryClient, QueryClientProvider} from "react-query";
import React, {useEffect, useRef, useState} from "react";
import {
    Autocomplete,
    Box,
    Button, Chip, FormHelperText, InputLabel, MenuItem,
    Modal, OutlinedInput, Select, TextField, Theme, useTheme
} from "@mui/material";
import {ACard} from "@atiautomacao/ati-ui-library";
import GridLeftRightSelect from "./GridSelecteTeleObjects";
import {FindTeleObjectsByEquipmentReactQuery} from "../GraphElementReactQuery";
import {SelectChangeEvent} from "@mui/material/Select";
import Container from "@mui/material/Container";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";

interface PropsModal {
    openModal: boolean;
    onCloseModal: () => void;
    equipment: any;
    setPowerStation: (id: number) => void;
    powerStation: (ps: any) => void;
    addElementToUnifilar: (data: any) => void;
    setSkid: (skidId: number) => void;
    clearUnifilar: () => void;
    dataToEdit: any;
    powerStationIdFromForm: number;
    skidIdFromForm: number;
    isUnifilarPreviousCreated: boolean;
    actualUnifilarElements: any[];
    listPowerStation: any[];
    unifilarToEdit: any;
}

const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    height: '65%',
    borderRadius: '25px'
}

const styleSkid = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    borderRadius: '25px'
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};

function getStyles(name: string, tobSelected: readonly string[], theme: Theme) {
    return {
        fontWeight:
            tobSelected.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const typesUnifilar = ['USINA', 'SKID'];

interface SelectValue {
    value: number;
    name: string
}

interface SkidPowerStation {
    id: number;
    name: string
}

interface UnifilarPowerStation {
    id: number;
    name: string;
    skidList: Array<SkidPowerStation>
}

export const ModalAddEquipment = ({openModal, onCloseModal, equipment, addElementToUnifilar, setSkid, setPowerStation, powerStation, clearUnifilar, dataToEdit, powerStationIdFromForm, skidIdFromForm, isUnifilarPreviousCreated, actualUnifilarElements, listPowerStation, unifilarToEdit}: PropsModal) => {
    const [equipmentList, setEquipmentList] = useState<any[]>([]);
    const [selectedEquipment, setSelectedEquipment] = useState(-1);
    const [teleObjectConfigList, setTeleObjectConfigList] = useState<any[]>([]);
    const [teleObjectList, setTeleObjectList] = useState<any[]>([]);
    const [tobSelected, setTobSelected] = useState<string[]>([]);
    const [rightValues, setRightValues] = useState<string[]>([]);
    const [leftValues, setLeftValues] = useState<string[]>([]);
    const [unifilarType, setUnifilarType] = useState('');
    const [selectedPowerStationId, setSelectedPowerStationId] = useState(-1);
    const [selectedPowerStation, setSelectedPowerStation] = useState<any>(null);
    const [selectedSkidId, setSelectedSkidId] = useState(-1);
    const [selectedSkid, setSelectedSkid] = useState<any>(null);
    const [skidList, setSkidList] = useState<SelectValue[]>([]);
    const [powerStationList, setPowerStationList] = useState<SelectValue[]>([]);
    const [autoCompleteList, setAutoCompleteList] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const prevSelectedPowerStationId = useRef(-1);
    const prevSelectedUnifilarType = useRef('');
    const prevSelectedSkidId = useRef(-1);
    const [leftValuesToEdit, setLeftValuesToEdit] = useState<string[]>([]);
    const [rightValuesToEdit, setRightValuesToEdit] = useState<string[]>([]);

    const theme = useTheme();

    const mutationFind = FindTeleObjectsByEquipmentReactQuery();

    useEffect(() => {
        if(equipment && selectedPowerStation) {
            let equipmentSkids: any[] = [];
            if(selectedSkid && equipment?.id !== 8) {
                selectedSkid.equipmentList.filter((eq: any) => eq.image?.id === equipment.id).forEach((eqp: any) => {
                    equipmentSkids.push({id: eqp.id, name: eqp.name, status: eqp.etsStatus});
                });
            } else {
                selectedPowerStation.skidList.forEach((skid: any) => {
                    skid.equipmentList.filter((eq: any) => eq.image?.id === equipment.id).forEach((eqp: any) => {
                        equipmentSkids.push({id: eqp.id, name: eqp.name + " - " + skid.name, status: eqp.etsStatus});
                    });
                });
            }

            equipmentSkids.sort((a: any, b: any) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            setEquipmentList(equipmentSkids);
        }
    }, [equipment, selectedPowerStation, selectedSkid]);

    useEffect(() => {
        let usinas = powerStationList;
        if(listPowerStation && listPowerStation.length > 0) {
            usinas = listPowerStation.map((ps: UnifilarPowerStation) => ({ value: ps.id, name: ps.name }));
            let autoCompleteUsinasList = usinas.map((ps) => ps.name)
            setPowerStationList(usinas);
            setAutoCompleteList(autoCompleteUsinasList);
        }
    }, [listPowerStation]);

    useEffect(() => {
        if(((prevSelectedPowerStationId.current !== -1 && prevSelectedPowerStationId.current !== selectedPowerStationId)
            || (prevSelectedSkidId.current !== -1 && prevSelectedSkidId.current !== selectedSkidId)
            || (prevSelectedUnifilarType.current !== '' && prevSelectedUnifilarType.current !== unifilarType)) && actualUnifilarElements.length > 0 && openModal){
            setOpenDialog(true);
        } else {
            let powerPlantSelected = listPowerStation.find((ps: UnifilarPowerStation) => ps.id === selectedPowerStationId);
            if(selectedPowerStationId !== -1 && powerPlantSelected) {
                setSelectedPowerStation(powerPlantSelected);
                powerStation(powerPlantSelected);
                setPowerStation(selectedPowerStationId);
                let skids = powerPlantSelected.skidList.map((skid: SkidPowerStation) => ({value: skid.id, name: skid.name}));
                skids.sort((a: any, b: any) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setSkidList(skids);
                skids.unshift({value: -1, name: 'Selecione a Skid'});
                prevSelectedUnifilarType.current = unifilarType;
                prevSelectedPowerStationId.current = selectedPowerStationId;
                if(equipment?.id !== 8) {
                    prevSelectedSkidId.current = selectedSkidId;
                }
            }
        }
    }, [unifilarType, selectedPowerStationId, selectedSkidId]);

    useEffect(() => {
        if(unifilarToEdit) {
            let powerPlantSelected = listPowerStation.find((ps: UnifilarPowerStation) => ps.id === powerStationIdFromForm);
            setSelectedPowerStationId(powerStationIdFromForm);
            setSelectedPowerStation(powerPlantSelected);
            if(skidIdFromForm && skidIdFromForm !== -1){
                setUnifilarType("SKID");
                setSelectedSkidId(skidIdFromForm);
                setSelectedSkid(powerPlantSelected?.skidList.find((skd: any) => skd.id === skidIdFromForm));
                let skids = powerPlantSelected?.skidList.map((skid: SkidPowerStation) => ({value: skid.id, name: skid.name}));
                skids?.sort((a: any, b: any) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setSkidList(skids);
            } else if(powerStationIdFromForm !== -1){
                setUnifilarType("USINA");
            }
        }
    }, [unifilarToEdit, powerStationIdFromForm, skidIdFromForm, listPowerStation]);

    useEffect(() => {
        if(dataToEdit) {
            setSelectedPowerStationId(powerStationIdFromForm);
            if(skidIdFromForm && skidIdFromForm !== -1){
                setUnifilarType("SKID");
                setSelectedSkidId(skidIdFromForm);
                setSelectedSkid(selectedPowerStation?.skidList.find((skd: any) => skd.id === skidIdFromForm))
            } else {
                setUnifilarType("USINA");
            }
            setSelectedEquipment(dataToEdit.equipmentId);
            let rightValuesSelected = dataToEdit.rightValues.map((v: any) => v.label);
            let leftValuesSelected = dataToEdit.leftValues.map((v: any) => v.label);
            setRightValuesToEdit(rightValuesSelected);
            setLeftValuesToEdit(leftValuesSelected);
        }
    }, [dataToEdit, skidIdFromForm, powerStationIdFromForm]);

    useEffect(() => {
        mutationFind.mutate(selectedEquipment, {
            onSuccess: (dataFound) => {
                let tobList = dataFound.data.map((teleObject: any) => {
                    return {idTeleObjeto: teleObject.id, id: teleObject.teleObjectConfig.id, name: teleObject.teleObjectConfig.name, unit: teleObject.teleObjectConfig.unit}
                });
                setTeleObjectConfigList(tobList);
                setTeleObjectList(dataFound.data);
                if(dataToEdit){
                    let teleObjetosSelecionados: string[] = [];
                    dataToEdit.rightValues.forEach((value: any) => {
                        teleObjetosSelecionados.push(value.label);
                    });
                    dataToEdit.leftValues.forEach((value: any) => {
                        teleObjetosSelecionados.push(value.label);
                    });
                    setTobSelected(teleObjetosSelecionados);
                }
            }
        });
    }, [selectedEquipment]);

    useEffect(() => {
        if(unifilarToEdit === undefined) {
            setSelectedPowerStationId(-1);
            setSelectedSkidId(-1);
            setSelectedEquipment(-1);
        }
    }, [unifilarType]);

    function handleSelectEquipment(equipmentId: number) {
        setSelectedEquipment(equipmentId);
        setTobSelected([]);
    }

    const handleSelectTeleObject = (event: SelectChangeEvent<typeof tobSelected>) => {
        const {
            target: { value },
        } = event;
        setTobSelected(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function handleCloseModal() {
        setSelectedEquipment(-1);
        if(equipment?.id === 8) {
            setSelectedSkidId(-1);
            prevSelectedSkidId.current = -1;
            setSelectedSkid(null);
        }
        setTobSelected([]);
        onCloseModal();
    }

    function handleLeftValues(leftVl: string[]) {
        setLeftValues(leftVl);
    }

    function handleRightValues(rightVl: string[]) {
        setRightValues(rightVl);
    }

    function getEquipmentName() {
        if(equipment?.id !== 8) {
            return equipmentList.find(eqp => eqp.id === selectedEquipment)?.name;
        } else {
            return skidList.find(eqp => eqp.value === selectedSkidId)?.name;
        }

    }

    function handleAddElement() {
        const crypto = window.crypto;
        const arrayCryptyo = new Uint16Array(1);
        crypto.getRandomValues(arrayCryptyo);
        let randomId = arrayCryptyo[0].toString();
        let rightValuesSelected = teleObjectConfigList.filter(telObj => rightValues.includes(telObj.name)).map(toc => {return {id: null, label: toc.name, value: 0, unit: toc.unit}});
        let leftValuesSelected = teleObjectConfigList.filter(telObj => leftValues.includes(telObj.name)).map(toc => {return {id: null, label: toc.name, value: 0, unit: toc.unit}});

        if(selectedSkid) {
            randomId = "e" + randomId;
        } else {
            randomId = "s" + randomId;
        }

        let elementToAdd = null;

        if(dataToEdit) {
            elementToAdd = dataToEdit;
            let leftValuesList: any[] = [];
            let rightValuesList: any[] = [];
            let leftIds = dataToEdit.leftValues.map((item: any) => item.metadataId);
            let rightIds = dataToEdit.rightValues.map((item: any) => item.metadataId);

            leftValuesSelected.forEach(value => {
                let valueFound = dataToEdit.leftValues.find((lf: any) => lf.label === value.label);
                if(!valueFound) valueFound = dataToEdit.rightValues.find((lf: any) => lf.label === value.label);
                if(valueFound) {
                    leftValuesList.push({id: valueFound.metadataId, label: valueFound.label, unit: valueFound.unit, value: valueFound.value});
                } else {
                    leftValuesList.push(value);
                }
            });
            rightValuesSelected.forEach(value => {
                let valueFound = dataToEdit.rightValues.find((lv: any) => lv.label === value.label);
                if(!valueFound) valueFound = dataToEdit.leftValues.find((rv: any) => rv.label === value.label);
                if(valueFound) {
                    rightValuesList.push({id: valueFound.metadataId, label: valueFound.label, unit: valueFound.unit, value: valueFound.value});
                } else {
                    rightValuesList.push(value);
                }
            });

            const combinedIds = new Set([...leftIds, ...rightIds]);

            let metadataToDelete: number[] = [];
            combinedIds.forEach(id => {
                if (!rightValuesList.some(item => item.id === id) && !leftValuesList.some(item => item.id === id)) {
                    metadataToDelete.push(id);
                }
            });

            elementToAdd.leftValues = leftValuesList;
            elementToAdd.rightValues = rightValuesList;
            elementToAdd.label = dataToEdit?.label;
            elementToAdd.equipmentId = selectedEquipment;
            elementToAdd.teleObjectList = dataToEdit.teleObjectList;
            elementToAdd.nodeId = dataToEdit.id;
            elementToAdd.metadataToDelete = metadataToDelete;
            elementToAdd.equipmentName = getEquipmentName();
        } else {
            elementToAdd = {
                id: randomId,
                label: equipment?.name,
                edges: [{id: randomId, label: "", edges: [], leftValues: [], rightValues: [], topValues: []}],
                leftValues: leftValuesSelected,
                rightValues: rightValuesSelected,
                topValues: [],
                icon: null,
                isNavigable: true,
                idImage: equipment?.id,
                status: "",
                equipmentId: selectedEquipment,
                teleObjectList: teleObjectList,
                nodeId: randomId,
                parentNode: null,
                skidId: selectedSkidId,
                metadataToDelete: [],
                equipmentName: getEquipmentName()
            };

        }
        addElementToUnifilar(elementToAdd);
        handleCloseModal();
    }

    function handleSelectUnifilarType(type: string) {
        setUnifilarType(type);
        if(type === 'USINA') {
            setSelectedSkid(null);
        }
    }

    function handleSelectedSkid(skidId: number) {
        setSelectedSkidId(skidId);
        setSkid(skidId);
        if(equipment?.id === 8) {
            prevSelectedSkidId.current = -1;
            setSelectedSkid(null);
            setSkid(-1);
        }
        if(selectedPowerStation && skidId !== -1) {
            setSelectedSkid(selectedPowerStation.skidList.find((skd: any) => skd.id === skidId));
        }
    }

    function handleCloseDialog() {
        setOpenDialog(false);
        setSelectedPowerStationId(prevSelectedPowerStationId.current);
        setUnifilarType(prevSelectedUnifilarType.current);
        setSelectedSkidId(prevSelectedSkidId.current);
    }

    function handleConfirmDialog() {
        clearUnifilar();
        setUnifilarType('');
        setSelectedPowerStationId(-1);
        setSelectedSkidId(-1);
        prevSelectedUnifilarType.current = '';
        prevSelectedPowerStationId.current = -1;
        prevSelectedSkidId.current = -1;
        setOpenDialog(false);
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={equipment?.id !== 8 ? style : styleSkid}>
                <ACard
                    title={dataToEdit ? "Alterar Equipamento" : "Adicionar Equipamento"}
                    styleProps={{
                        cardStyle: {overflow: 'auto', padding: 2}
                    }}
                    footerActions={
                        <>
                            <Button
                                variant="contained"
                                color={"primary"}
                                style={{marginBottom: 20, marginRight: 10}}
                                onClick={onCloseModal}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color={"primary"}
                                style={{marginBottom: 20}}
                                onClick={handleAddElement}

                            >
                                Adicionar
                            </Button>
                        </>
                    }
                >
                    <Container style={{marginLeft: -10, marginBottom: 50, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', columnGap: 10}}>
                            {
                                equipment?.id !== 8 ?
                                    <Box style={{marginBottom: 20, width: '30%'}}>
                                        <InputLabel id="selectTipo">Tipo de Unifilar</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="selectTipo"
                                            id="selectEquipmentType"
                                            placeholder={"Selecione o tipo"}
                                            value={unifilarType}
                                            label="Tipo"
                                            onChange={(value) => handleSelectUnifilarType(value.target.value)}
                                            variant={"standard"}
                                            required
                                            disabled={isUnifilarPreviousCreated}
                                            sx={{height: 48}}
                                        >
                                            {
                                                typesUnifilar.map((type) => {
                                                    return (
                                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                    </Box> : ""
                            }
                            <Box style={{marginBottom: 20, width: '30%'}}>
                                <InputLabel id="selectUsina">Usina</InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={selectedPowerStation?.name}
                                    options={autoCompleteList}
                                    sx={{ width: 250 }}
                                    disabled={isUnifilarPreviousCreated}
                                    onChange={(event, newValue) => {
                                        const selected = powerStationList.find(usina => usina.name === newValue) ?? null;
                                        if (selected) {
                                            setSelectedPowerStationId(selected.value);
                                        }
                                    }}
                                    renderInput={
                                        (params) => <TextField {...params} label="Seleciona a Usina" variant={"standard"}/>
                                    }
                                />
                                {
                                    unifilarType === '' && <FormHelperText id="my-helper-text">Selecione o tipo de Unifilar.</FormHelperText>
                                }
                                {
                                    unifilarType !== '' && selectedPowerStationId === -1 && <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            {
                                unifilarType === 'SKID' || equipment?.id === 8 ?
                                    <Box style={{marginBottom: 20, width: '30%'}}>
                                        <InputLabel id="selectSkid">Skid</InputLabel>
                                        <Select
                                            error={selectedSkidId === -1}
                                            fullWidth
                                            labelId="selectSkid"
                                            id="selectSkid"
                                            placeholder={"Selecione a Usina"}
                                            value={selectedSkidId}
                                            label="Skid"
                                            onChange={(value) => handleSelectedSkid(value.target.value as number)}
                                            variant={"standard"}
                                            required
                                            disabled={selectedPowerStationId === -1 || (isUnifilarPreviousCreated && equipment?.id !== 8)}
                                            sx={{height: 48}}
                                        >
                                            {
                                                skidList?.map((ps) => {
                                                    return (
                                                        <MenuItem key={ps.value} value={ps.value}>{ps.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        {
                                            selectedPowerStationId === -1 && <FormHelperText id="my-helper-text">Selecione uma Usina.</FormHelperText>
                                        }
                                        {
                                            selectedSkidId === -1 && <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                        }
                                    </Box> : ""
                            }
                        </div>
                    </Container>
                    {
                        equipment?.id !== 8 ?
                            <>
                                <Box style={{marginBottom: 20, width: '50%'}}>
                                    <InputLabel id="selectEquipment">Selecione o Equipamento</InputLabel>
                                    <Select
                                        error={selectedEquipment === -1}
                                        fullWidth
                                        labelId="selectEquipment"
                                        id="selectEquipment"
                                        placeholder={"Selecione o Equipamento"}
                                        value={selectedEquipment}
                                        label="Equipamento"
                                        onChange={(value) => handleSelectEquipment(value.target.value as number)}
                                        variant={"standard"}
                                        required
                                    >
                                        {
                                            equipmentList.map((eqp) => {
                                                return (
                                                    <MenuItem key={eqp.id} value={eqp.id}>{eqp.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </Box>
                                <Box style={{marginBottom: 20, width: '100%'}}>
                                    <InputLabel id="teleObjectsMultipleSelect">Selecione os Teleobjetos</InputLabel>
                                    <Select
                                        labelId="teleObjectsMultipleSelect"
                                        id="multiTeleObjects"
                                        multiple
                                        value={tobSelected}
                                        onChange={handleSelectTeleObject}
                                        style={{width: '100%'}}
                                        input={<OutlinedInput id="teleObjectsSelect" label="Teleobjeto"/>}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value}/>
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        disabled={selectedEquipment === -1}
                                    >
                                        {teleObjectConfigList.map((toc) => (
                                            <MenuItem
                                                key={toc.id}
                                                value={toc.name}
                                                style={getStyles(toc.name, tobSelected, theme)}
                                            >
                                                {toc.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <GridLeftRightSelect teleObjectsAvailable={tobSelected} setLeftValues={handleLeftValues}
                                                     setRightValues={handleRightValues} leftValuesToEdit={leftValuesToEdit} rightValuesToEdt={rightValuesToEdit}/>
                            </> : ""
                    }
                </ACard>
                <ConfirmDialog
                    title={'Alteração de Dados'}
                    description={'Essa alteração na Usina ou Skid desfaz o trabalho atual, deseja continuar?'}
                    open={openDialog}
                    handleConfirm={handleConfirmDialog}
                    handleClose={handleCloseDialog}
                />
            </Box>
        </Modal>
    )
}

export default function ModalAddEquipmentHome({openModal, onCloseModal, equipment, addElementToUnifilar, setSkid, setPowerStation, powerStation, clearUnifilar, dataToEdit, powerStationIdFromForm, skidIdFromForm, isUnifilarPreviousCreated, actualUnifilarElements, listPowerStation, unifilarToEdit}: Readonly<PropsModal>) {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModalAddEquipment
                openModal={openModal}
                onCloseModal={onCloseModal}
                equipment={equipment}
                setPowerStation={setPowerStation}
                powerStation={powerStation}
                addElementToUnifilar={addElementToUnifilar}
                setSkid={setSkid}
                clearUnifilar={clearUnifilar}
                dataToEdit={dataToEdit}
                powerStationIdFromForm={powerStationIdFromForm}
                skidIdFromForm={skidIdFromForm}
                isUnifilarPreviousCreated={isUnifilarPreviousCreated}
                actualUnifilarElements={actualUnifilarElements}
                listPowerStation={listPowerStation}
                unifilarToEdit={unifilarToEdit}
            />
        </QueryClientProvider>
    )
}