import React, {useEffect, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Config/Hooks';
import { getEntities, reset } from './PowerStationElementsReducer';
import type { RootState } from '../../../../Config/Store';
import {mapElementToNode} from "../../../../Shared/Helpers/mapElementToNode";
import {ACard, Flow, Skeleton} from "@atiautomacao/ati-ui-library";
import { useSkidNavigation } from '../../../../Shared/Hooks/useSkidNavigation';
import { useEquipmentNavigation } from '../../../../Shared/Hooks/useEquipmentNavigation';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import type {Element} from '../../../../Shared/Types/element';
import useInterval from "../../../../Shared/Hooks/useInterval";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {CommandDTO} from "../../../../Shared/Components/ModalForSendingTelecommand";
import {SendingTelecommandUnifilar} from "../../../../Shared/Components/SendingTelecommandUnifilar";
import {useSnackbar} from "notistack";
import {findAllCommands} from "../../../../Utils/CommandService";

interface Props {
    powerStationId: number;
}

const PowerStationElements = ({ powerStationId }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigateToSkid = useSkidNavigation();
    const navigateToEquipment = useEquipmentNavigation();
    const { entities, isLoading } = useAppSelector((state: RootState) => state.powerStationElements);
    const { selectedPowerPlant } = useAppSelector((state: RootState) => state.dashboard);
    const [elementsData, setElementsData] = useState<any[]>([])
    const [equipmentId, setEquipmentId] = useState<number>(-1);
    const [teleCommandList, setTeleCommandList] = useState<Array<CommandDTO>>([])
    const [open, setOpen] = useState(false)


    useEffect(() => {
        dispatch(reset())
        dispatch(getEntities(powerStationId));
    }, [selectedPowerPlant]);

    useInterval(() => {
        dispatch(reset())
        dispatch(getEntities(powerStationId));
    }, 60000); // 5 minutes

    useEffect(() => {
        if(entities && entities.elements.length > 0) {
            let nodesElements = [...entities.elements];
            nodesElements.forEach((elt: any) => {
                if(elt.parentId !== null && !nodesElements.find((elm: any) => elm.id === elt.parentId)) {
                    nodesElements.push({
                        id: elt.parentId,
                        label: elt.parentId.toUpperCase(),
                        edges: [],
                        leftValues: [],
                        rightValues: [],
                        topValues: [],
                        icon: '',
                        isNavigable: true,
                        status: '',
                        type: 'group',
                        idImage: undefined
                    })
                }
            });

            setElementsData(nodesElements);
        }
    }, [entities]);

    const handleNodeClick = (id: string, isNavigable: boolean, label: string): void => {
        const newID = id?.replace(/\D/g, "")
        let node = entities?.elements.find(element => element.id === id);
        if (node?.isNavigable) {
            let idNumber = Number(newID);
            if(label.toLowerCase().includes('unidade geradora') || label.toLowerCase().includes('skid')){
                navigateToSkid(selectedPowerPlant.id, idNumber);
                return
            }
            navigateToEquipment(idNumber);
        }
    }
    const sortTreePerSkid = (elements:Element[]): Element[] => {
        const newElements = elements.map(element => ({
            ...element,
            edges: [...element.edges]
        }));
        for(const element of newElements){
            element.edges = sortPerSkid({...element})
        }
        return newElements
    }

    const sortNodeChildren = (node: Element): Element => {
        const sortedEdges = node.edges.map(edge => sortNodeChildren(edge));
        sortedEdges.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
        return { ...node, edges: sortedEdges };
    };


    const compareGeneratorUnits = (labelA: string, labelB: string): number => {
        const isAGeneratorUnit = labelA.toLowerCase().includes('unidade geradora') || labelA.toLowerCase().includes('skid');
        const isBGeneratorUnit = labelB.toLowerCase().includes('unidade geradora') || labelB.toLowerCase().includes('skid');

        if (isAGeneratorUnit && !isBGeneratorUnit) {
            return -1;
        } else if (!isAGeneratorUnit && isBGeneratorUnit) {
            return 1;
        } else {
            return labelA.localeCompare(labelB);
        }
    };

    const traverseAndSortTree = (elements: Element[]): Element[] => {
        return elements.map(element => sortNodeChildren(element)).sort((a, b) => {
            return compareGeneratorUnits(a.label, b.label);
        });
    };

    const sortPerSkid = (element: Element): Element[] => {
        return element.edges.sort((a, b) => {
            return compareGeneratorUnits(findSkid(a), findSkid(b));
        });
    };

    const findSkid = (element: Element): string => {
        if (element.label.toLowerCase().includes('unidade geradora') || element.label.toLowerCase().includes('skid')) {
            return element.label;
        }
        if (element.edges) {
            for (const edge of element.edges) {
                const result = findSkid(edge);
                if (result) return result;
            }
        }
        return element.label;
    };

    const handleCloseModal = () => {
        setOpen(false);
        setTeleCommandList([])
        setMenuPosition(null);
        setEquipmentId(-1)
    };

    const [menuPosition, setMenuPosition] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleNodeContextMenu = (event: React.MouseEvent, node: any) => {
        event.preventDefault();
        if(node.id != null){
            if(node.id.startsWith('e')){
                setMenuPosition({
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                });
                const id = node.id.substring(1);
                setEquipmentId(Number(id));
            }
        }else{
            setMenuPosition(null);
            setEquipmentId(-1)
        }

    };

    const handleMenuContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setMenuPosition(null);
        setEquipmentId(-1)

    };
    const toOpen = () => {
        if(teleCommandList.length > 0){
            setOpen(true)
        }else{
            enqueueSnackbar("Comandos não encontrados", {variant: "error"});
            setOpen(false)
            setMenuPosition(null);
            setEquipmentId(-1)
        }

    }
    const handleClose = () => {
        setMenuPosition(null);
        setEquipmentId(-1)
    };

    useEffect(() => {
        if (equipmentId !== -1) {
            findAllCommands(equipmentId).then(
                (response:any) => {
                    if (response.status === 204) {
                        setTeleCommandList([]);
                    } else {
                        setTeleCommandList(response.data.data);
                    }
                }
            ).catch((error:any) => {
                setTeleCommandList([]);
                console.error("Error fetching tele commands:", error);
            })
        }else{
            setTeleCommandList([]);
        }
    }, [equipmentId]);

    let cardContentToRender: JSX.Element;
    if (isLoading) {
        cardContentToRender = <Skeleton animation="wave" height={500} variant="rectangular" width={800} />
    } else if (entities && elementsData && elementsData.length > 0) {
        const newEntities = traverseAndSortTree([...elementsData])
        cardContentToRender = (
            <div style={{height: 500, width: '100%'}}>
                    <Flow
                        nodes={sortTreePerSkid(newEntities).map(element => mapElementToNode(element, entities.images))}
                        onNodeClick={handleNodeClick} isCreating={false}
                        onContextMenu={handleNodeContextMenu}

                    />
                    <Menu
                        open={menuPosition !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        disablePortal
                        onContextMenu={handleMenuContextMenu}
                        anchorPosition={
                            menuPosition !== null
                                ? {top: menuPosition.mouseY, left: menuPosition.mouseX}
                                : undefined
                        }
                    >
                        <MenuItem onClick={toOpen}>Enviar comando</MenuItem>
                    </Menu>
            </div>
        );
    } else {
        cardContentToRender = (
            <Container fixed>
                <Box sx={{
                    bgcolor: '#e1e0e0', height: '500px', width: '800px', alignItems: 'center',
                    justifyContent: 'center', display: 'flex'
                }}>
                    <div style={{textAlign: 'center', fontSize: '23px', color: '#646363'}}>
                        <span>Nenhum equipamento encontrado</span>
                    </div>
                </Box>

            </Container>
        );
    }

    return (
        <>
            <ACard
                styleProps={{
                    cardStyle: {height: 700, width:'100%'}
                }}
                title="Unifilar"
                // headerActions={<HeaderActions/>}
                // footerActions={<FooterActions/>}
            >
                {cardContentToRender}
            </ACard>
            {teleCommandList != null && teleCommandList.length > 0 && <SendingTelecommandUnifilar commandDTOList={teleCommandList} open={open} onCloseModal={handleCloseModal}/>}
        </>
    );
};

export default PowerStationElements;
