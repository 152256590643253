// Dark Theme
import { createTheme, Theme, ThemeOptions } from '@mui/material';

export const darkThemeConfig: ThemeOptions = {
  palette: {
    mode: 'dark',
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
          '& .svg-inline--fa fa-house': {
            color: '#fff'
          }
        },
      },
    },
  }
};
export const DarkTheme: Theme = createTheme(darkThemeConfig);
