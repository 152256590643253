import {
    Button, Checkbox,
    Container,
    FormControl,
    FormHelperText, Grid, ListItemText,
    OutlinedInput,
    Select,
    TextField,
    Tooltip, useTheme
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import React, {useCallback, useEffect, useState} from "react";
import CustomAppBar from "../GraficElement/CustomAppBar";
import {ACard, ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import {FindNotificationGroupByIdReactQuery, SaveNotificationGroupReactQuery} from "./reactQuery/NotificationGroupReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {
    DeleteNotificationGroupItemReactQuery,
    NotificationGroupItemReactQuery
} from "./reactQuery/NotificationGroupItemReactQuery";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {columnsDataGrid, columnsNotificationGroupItemGrid} from "../GraficElement/columnsDataGrid";
import {CustomerReactQuery} from "./reactQuery/CustomerReactQuery";
import MenuItem from "@mui/material/MenuItem";
import {FindAllEnabledPowerStationsReactQuery} from "../PowerStation/PowerStationReactQuery";
import {FindAllAlarmSeverityReactQuery} from "../AlarmSeverity/AlarmSeverityReactQuery";
import {FindAllSkidsByPowerStationIdReactQuery} from "./reactQuery/SkidReactQuery";
import {FindAllEquipmentsBySkidNullOrNotReactQuery, FindAllTeleObjectsByEquipmentIdReactQuery} from "../Equipment/EquipmentReactQuery";
import {AuthorityReactQuery} from "./reactQuery/AuthorityReactQuery";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};
export interface RowNotificationGroupItem {
    id?: number;
    powerStationId: number;
    powerStationName: string;
    skidName: string | null;
    skidId: number | null;
    equipmentName: string | null;
    equipmentId: number | null;
    teleObjectName: string | null;
    teleObjectId: number | null;
    authorityName: string | null;
    authorityId: number | null;
    alarmSeverityName: string | null;
    alarmSeverityId: number | null;
    index?: number;
    actionsConfig?: Array<ActionConfig>
}

export interface EquipmentType {
    id: number;
    name: string;
}

export interface EquipmentClass {
    id: number;
    name: string;
    description: string;
    equipmentType: EquipmentType;
    equipmentClassConfig: any;
    teleObjectConfigEntityList: any;
}

export interface Equipment {
    id: number;
    name: string;
    etsStatus: string;
    equipmentType: EquipmentType;
    equipmentClass: EquipmentClass;
    powerStation: any ;
    skid: any ;
    image: any;
    isHidden: boolean;
    selected: any ;
}
export interface TeleObjectType {
    id: number;
    name: string;
}
export interface TeleObjectSpecificationType {
    id: number;
    name: string;
}


export interface TeleObjectConfig {
    id: number;
    name: string;
    alarmedName: string;
    normalName: string;
    equipmentClass: EquipmentClass;
    teleObjectType: TeleObjectType;
    teleObjectSpecificationType: TeleObjectSpecificationType;
    severity: any;
    address: string;
    teleObjectConvertExpression: string | null;
    unit: string;
    unity: any;
    note: string | null;
    createEvent: boolean;
    modBusObjectType: any;
    point: number;
    normalAlarmCondition: string | null;
    modBusDataType: any;
    variation: string | null;
    discardLowerLimit: number | null;
    discardUpperLimit: number | null;
    commandVariables: any ;
    teleObjectDataType: any;
    pointTag: string | null;
    inputType: any;
    teleObjectAlarmConditionsEntity: any ;
    reference: string | null;
}

export interface TeleObject {
    id: number;
    status: string;
    equipment: Equipment;
    teleObjectConfig: TeleObjectConfig;
    teleObjectPosition: number;
    teleObjectSpecificationType: TeleObjectSpecificationType;
    selected: boolean;
}

export interface AlarmSeverity {
    id: number;
    name: string;
    color: string;
    selected: boolean;
}

export interface Authority {
    id: number;
    name: string;
    displayName: string;
    domain: any;
    selected: boolean;
}

export const NotificationGroupFormPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state?.id as string;
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsNotificationGroupItemGrid), 0, 100));
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowNotificationGroupItem>>([]);
    const [nextIndex, setNextIndex] = useState(0);
    const theme = useTheme();
    const [notificationGroupName, setNotificationGroupName] = useState<string>("");
    const [checkedPowerStations, setCheckedPowerStations] = useState<{
        id: number
        name: string;
        selected: boolean;
        skidList: Array<any>
    }[]>([]);
    const [selectedPowerStation, setSelectedPowerStation] = useState<{
        id: number;
        name: string;
        selected: boolean;
        skidList: Array<any>;
    } | null>(null);
    const [checkedSkids, setCheckedSkids] = useState<{
        id: number
        name: string;
        selected: boolean;
    }[]>([]);
    const [selectedSkid, setSelectedSkid] = useState<{
        id: number;
        name: string;
        selected: boolean;
    } | null>(null);
    const [checkedEquipments, setCheckedEquipments] = useState<Equipment[]>([]);
    const [selectedEquipment, setSelectedEquipment] = useState<Equipment | null>(null);
    const [checkedTeleObjects, setCheckedTeleObjects] = useState<TeleObject[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [checkedAlarmsSeverity, setCheckedAlarmsSeverity] = useState<AlarmSeverity[]>([]);
    const [checkedAuthorities, setCheckedAuthorities] = useState<Authority[]>([]);
    const {data, isSuccess} = NotificationGroupItemReactQuery(pagedSearchParams, id);
    const [tempRow, setTempRow] = useState<RowNotificationGroupItem | null>(null);


    const {data : customers} = CustomerReactQuery();
    const {data: powerStationsEnabled} = FindAllEnabledPowerStationsReactQuery();
    const {data: alarmsSeverity} = FindAllAlarmSeverityReactQuery();
    const{data: skids} = FindAllSkidsByPowerStationIdReactQuery(selectedPowerStation?.id);
    const{data: equipments} = FindAllEquipmentsBySkidNullOrNotReactQuery(selectedPowerStation?.id, selectedSkid?.id);
    const{data: teleObjects} = FindAllTeleObjectsByEquipmentIdReactQuery(selectedEquipment?.id);
    const{data: authorities} = AuthorityReactQuery();
    const mutation = DeleteNotificationGroupItemReactQuery(pagedSearchParams);

    useEffect(() => {
        if(powerStationsEnabled) {
            const initializedPowerStations = powerStationsEnabled?.data.map((powerStation: { id: number; name: string; }) => ({
                id: powerStation.id,
                name: powerStation.name,
                selected: false,
                skidList: []
            }));
            setCheckedPowerStations(initializedPowerStations);
        }
    }, [powerStationsEnabled]);


    useEffect(() => {
        // Verifica se 'skids' e 'skids.data' estão disponíveis antes de mapear
        if (skids?.data && Array.isArray(skids.data)) {
            const initializedSkids = skids?.data.map((skid: { skidId: number; skidName: string; }) => ({
                id: skid.skidId,
                name: skid.skidName,
                selected: false,
            }));
            setCheckedSkids(initializedSkids);
        } else {
            // Caso contrário, inicializa com um array vazio ou faz outro tratamento necessário
            setCheckedSkids([]);
        }
    }, [skids]);

    useEffect(() => {
        if (equipments?.data && Array.isArray(equipments.data)) {
            const initializedEquipments = equipments?.data.map((equipment: Equipment) => ({
                id: equipment.id,
                name: equipment.name,
                etsStatus: equipment.etsStatus,
                equipmentType: equipment.equipmentType,
                equipmentClass: equipment.equipmentClass,
                powerStation: equipment.powerStation,
                skid: equipment.skid,
                image: equipment.image,
                isHidden: equipment.isHidden,
                selected: false,
            }));
            setCheckedEquipments(initializedEquipments);
        } else {
            setCheckedEquipments([]);
        }
    }, [equipments]);

    useEffect(() => {
        if (teleObjects?.data && Array.isArray(teleObjects.data)) {
            const initializedTeleObjects = teleObjects?.data.map((teleObject: TeleObject) => ({
                id: teleObject.id,
                status: teleObject.status,
                equipment: teleObject.equipment,
                teleObjectConfig: teleObject.teleObjectConfig,
                teleObjectPosition: teleObject.teleObjectPosition,
                teleObjectSpecificationType: teleObject.teleObjectSpecificationType,
                selected: false,
            }));
            setCheckedTeleObjects(initializedTeleObjects);
        } else {
            setCheckedTeleObjects([]);
        }
    }, [teleObjects]);

    useEffect(() => {
        if (authorities?.data && Array.isArray(authorities.data)) {
            const initializedAuthorities = authorities?.data.map((authority: Authority) => ({
                id: authority.id,
                name: authority.name,
                displayName: authority.displayName,
                domain: authority.domain,
                selected: false
            }));
            setCheckedAuthorities(initializedAuthorities);
        } else {
            setCheckedAuthorities([]);
        }
    }, [authorities]);


    useEffect(() => {
        if (alarmsSeverity?.data && Array.isArray(alarmsSeverity.data)) {
            const initializedAlarmsSeverity = alarmsSeverity?.data.map((alarmSeverity: AlarmSeverity) => ({
                id: alarmSeverity.id,
                name: alarmSeverity.name,
                color: alarmSeverity.color,
                selected: false,
            }));
            setCheckedAlarmsSeverity(initializedAlarmsSeverity);
        } else {
            setCheckedAlarmsSeverity([]);
        }
    }, [alarmsSeverity]);



    useEffect(() => {
        const selected = checkedPowerStations.find(item => item.selected);
        setSelectedPowerStation(selected || null); // Se nenhum estiver selecionado, define como null
    }, [checkedPowerStations]);

    useEffect(() => {
        const selected = checkedSkids.find(item => item.selected);
        setSelectedSkid(selected || null); // Se nenhum estiver selecionado, define como null
    }, [checkedSkids]);

    useEffect(() => {
        const selected = checkedEquipments.find(item => item.selected);
        setSelectedEquipment(selected || null); // Se nenhum estiver selecionado, define como null
    }, [checkedEquipments]);

    const handleChangePowerStationCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedPowerStations = checkedPowerStations.map((powerStation, i) => ({
            ...powerStation,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setCheckedPowerStations(updatedPowerStations);
    };

    const handleChangeSkidsCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedSkids = checkedSkids.map((skid, i) => ({
            ...skid,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setCheckedSkids(updatedSkids);
    };

    const handleChangeEquipmentsCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedEquipments = checkedEquipments.map((equipment, i) => ({
            ...equipment,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setCheckedEquipments(updatedEquipments);
    }

    const handleChangeTeleObjectsCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedTeleObjects = checkedTeleObjects.map((teleObject, i) => ({
            ...teleObject,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setCheckedTeleObjects(updatedTeleObjects);
    }

    const handleChangeAuthorityCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedAuthorities = checkedAuthorities.map((authority, i) => ({
            ...authority,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setCheckedAuthorities(updatedAuthorities);
    };

    const handleChangeAlarmsSeverityCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedAlarmSeverity = checkedAlarmsSeverity.map((alarmSeverity, i) => ({
            ...alarmSeverity,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setCheckedAlarmsSeverity(updatedAlarmSeverity);
    };


    const mutationFindById = FindNotificationGroupByIdReactQuery();
    const mutationSave = SaveNotificationGroupReactQuery();


    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    function handleSave() {

        const selectedProfileId = customers?.data?.[0]?.id;
        // Verifique se o nome do grupo de notificação foi fornecido
        if(notificationGroupName === ""){
            return;
        }

        // Mapeie o estado 'rows' para criar a lista 'notificationGroupItem'
        const notificationGroupItem = rows.map(row => ({
            powerStation: {
                id: row.powerStationId,
            },
            skid: {
                id: row.skidId ?? null, // Se skidId não estiver definido, defina como null
            },
            equipment: {
                id: row.equipmentId ?? null, // Se equipmentId não estiver definido, defina como null
            },
            teleObject: {
                id: row.teleObjectId ?? null, // Se teleObjectId não estiver definido, defina como null
            },
            authority: {
                id: row.authorityId ?? null, // Se authorityId não estiver definido, defina como null
            },
            alarmSeverity: {
                id: row.alarmSeverityId ?? null, // Se alarmSeverityId não estiver definido, defina como null
            },
            enabled: true,
        }));

        // Crie o objeto a ser salvo
        const dataToSave = {
            name: notificationGroupName,
            customer: {
                id: selectedProfileId, // Substitua por um valor apropriado
            },
            enabled: true,
            notificationGroupItem,
        };

        // Salve o objeto
        const objectToSave = id ? {id: Number(id), ...dataToSave} : dataToSave;
        mutationSave.mutate(objectToSave, {
            onSuccess: (data: any) => {
                navigate(SystemRoutes.SETTINGS_NOTIFICATION_GROUP);
            }
        });

        console.log("objectToSave: ", objectToSave)
    }

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    setNotificationGroupName(data?.data?.name);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.data?.totalOfRecords);
            if (data?.data) {
                let rowsNotificationGroupItem = data.data?.data.map((notificationGroupItem: any) => {
                    return {
                        id: notificationGroupItem.id,
                        powerStationId: notificationGroupItem.powerStation.id,
                        powerStationName: notificationGroupItem.powerStation.name,
                        skidName: notificationGroupItem?.skid?.name ?? '',
                        skidId: notificationGroupItem?.skid?.id,
                        equipmentName: notificationGroupItem?.equipment?.name,
                        equipmentId: notificationGroupItem?.equipment?.id,
                        teleObjectName: notificationGroupItem?.teleObjectConfigName,
                        teleObjectId: notificationGroupItem?.teleObjectId,
                        authorityName: notificationGroupItem?.authority?.name,
                        authorityId: notificationGroupItem?.authority?.id,
                        alarmSeverityName: notificationGroupItem?.alarmSeverity?.name,
                        alarmSeverityId: notificationGroupItem?.alarmSeverity?.id,
                        actionsConfig: [
                            {
                                actionName: "Excluir",
                                disabled: false
                            }
                        ]
                    }
                });
                setRows(rowsNotificationGroupItem);
            }
        }
    }, [data?.data]);


    useEffect(() => {
        if (selectedPowerStation) {
            setTempRow({// Gerar um ID aleatório para a nova linha
                powerStationId: selectedPowerStation?.id,
                index: nextIndex,
                powerStationName: selectedPowerStation?.name,
                skidName: selectedSkid?.name ?? null,
                skidId: selectedSkid?.id ?? null,
                equipmentName: selectedEquipment?.name ?? null,
                equipmentId: selectedEquipment?.id ?? null,
                teleObjectName: checkedTeleObjects.find(item => item.selected)?.teleObjectConfig.name ?? null,
                teleObjectId: checkedTeleObjects.find(item => item.selected)?.id ?? null, // ou valor padrão
                authorityName: checkedAuthorities.find(item => item.selected)?.name ?? null,
                authorityId: checkedAuthorities.find(item => item.selected)?.id ?? null, // ou valor padrão
                alarmSeverityName: checkedAlarmsSeverity.find(item => item.selected)?.name ?? null,
                alarmSeverityId: checkedAlarmsSeverity.find(item => item.selected)?.id ?? null, // ou valor padrão
                actionsConfig: [
                    {
                        actionName: "Excluir",
                        disabled: false
                    }
                ]
            });
            // Incrementa o próximo índice
            setNextIndex(prevIndex => prevIndex + 1);
        }
    }, [selectedEquipment, selectedPowerStation, checkedTeleObjects, checkedAlarmsSeverity, checkedAuthorities, selectedSkid]);

    console.log("tempRows: ", tempRow)

    const handleBack = () => {
        navigate(SystemRoutes.SETTINGS_NOTIFICATION_GROUP);
    }


    const handleDeleteNotificationGroupItem = useCallback((data: any) => {
        setSelectedToDelete(data);
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        if (selectedToDelete.id) {
            // Se a linha tiver um `id`, trata como uma linha persistida
            setRows(prevRows => prevRows.filter(row => row.id !== selectedToDelete.id));
        } else {
            // Se a linha não tiver um `id`, use o `index` para identificar
            setRows(prevRows => prevRows.filter(row => row.index !== selectedToDelete.index));
        }

        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }


    useEffect(() => {
        if (mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 50));
        }
    }, [mutation.isSuccess]);

    return (
        <>
            <CustomAppBar
                handleSave={handleSave}
                handleBack={handleBack}
                rows={rows}
            />

            <Box marginTop={10}>
                <ACard>
                    <Container>
                        <Grid container spacing={2}>
                            {/* Primeira linha: Input + 3 Selects */}
                            <Grid item xs={3}>
                                <InputLabel id="object-name">Nome</InputLabel>
                                <TextField
                                    error={notificationGroupName === ""}
                                    fullWidth
                                    id="object-name"
                                    variant="standard"
                                    placeholder={"Nome do Grupo de Notificação"}
                                    onChange={(value) => setNotificationGroupName(value.target.value)}
                                    value={notificationGroupName}
                                    style={{ width: '100%' }} // Ajuste para preencher o grid
                                />
                                {notificationGroupName === "" && (
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-powerStations-label">Usinas</InputLabel>
                                    <Select
                                        labelId="select-powerStations-label"
                                        id="select-powerStations"
                                        multiple
                                        value={checkedPowerStations.filter(item => item.selected).map(item => item.name)}
                                        input={<OutlinedInput label="Usinas" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    >
                                        {powerStationsEnabled?.data.map((powerStation: any) => (
                                            <MenuItem
                                                key={powerStation.id}
                                                value={powerStation.name || ''}
                                            >
                                                <Checkbox
                                                    checked={checkedPowerStations.find(item => item.name === powerStation.name)?.selected || false}
                                                    onChange={(e) => handleChangePowerStationCheckbox(e, checkedPowerStations.findIndex(item => item.name === powerStation.name))}
                                                />
                                                <ListItemText primary={powerStation.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-skids-label">Skids</InputLabel>
                                    <Select
                                        labelId="select-skids-label"
                                        id="select-skids"
                                        multiple
                                        value={checkedSkids.filter(item => item.selected).map(item => item.name)}
                                        input={<OutlinedInput label="Skids" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    >
                                        {skids?.data ? (
                                            skids?.data.map((skid: any) => (
                                                <MenuItem key={skid.skidId} value={skid.skidName || ''}>
                                                    <Checkbox
                                                        checked={checkedSkids.find(skidItem => skidItem.name === skid.skidName)?.selected || false}
                                                        onChange={(e) => handleChangeSkidsCheckbox(e, checkedSkids.findIndex(skidItem => skidItem.name === skid.skidName))}
                                                    />
                                                    <ListItemText primary={skid.skidName} />
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>
                                                <ListItemText primary="Nenhum skid disponível" />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-equipments-label">Equipamentos</InputLabel>
                                    <Select
                                        labelId="select-equipments-label"
                                        id="select-equipments"
                                        multiple
                                        value={checkedEquipments.filter(item => item.selected).map(item => item.name)}
                                        input={<OutlinedInput label="Equipamentos" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    >
                                        {equipments?.data ? (
                                            equipments?.data.map((equipment: Equipment) => (
                                                <MenuItem key={equipment.id} value={equipment.name || ''}>
                                                    <Checkbox
                                                        checked={checkedEquipments.find(equipmentItem => equipmentItem.name === equipment.name)?.selected || false}
                                                        onChange={(e) => handleChangeEquipmentsCheckbox(e, checkedEquipments.findIndex(profile => profile.name === equipment.name))}
                                                    />
                                                    <ListItemText primary={equipment.name} />
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>
                                                <ListItemText primary="Nenhum equipamento disponível" />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-teleObjects-label">Tele Objetos</InputLabel>
                                    <Select
                                        labelId="select-teleObjects-label"
                                        id="select-teleObjects"
                                        multiple
                                        value={checkedTeleObjects.filter(item => item.selected).map(item => item.teleObjectConfig.name)}
                                        input={<OutlinedInput label="Tele Objetos" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    >
                                        {
                                            teleObjects?.data ? (
                                                teleObjects?.data.map((teleObject : TeleObject) => (
                                                    <MenuItem
                                                        key={teleObject.id}
                                                        value={teleObject.teleObjectConfig.name || ''} // Ensure value is always a string
                                                    >
                                                        <Checkbox
                                                            checked={checkedTeleObjects.find(profile => profile.teleObjectConfig.name === teleObject.teleObjectConfig.name)?.selected || false}
                                                            onChange={(e) => handleChangeTeleObjectsCheckbox(e, checkedTeleObjects.findIndex(profile => profile.teleObjectConfig.name === teleObject.teleObjectConfig.name))}
                                                        />
                                                        <ListItemText primary={teleObject.teleObjectConfig.name} />
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>
                                                    <ListItemText primary="Nenhum tele objeto disponível" />
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-alarmSeverity-label">Severidade de Alarmes</InputLabel>
                                    <Select
                                        labelId="select-alarmSeverity-label"
                                        id="select-alarmSeverity"
                                        multiple
                                        value={checkedAlarmsSeverity.filter(item => item.selected).map(item => item.name)}
                                        input={<OutlinedInput label="Severidade de Alarmes" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    >
                                        {alarmsSeverity?.data.map((alarmSeverity : any) => (
                                            <MenuItem
                                                key={alarmSeverity.id}
                                                value={alarmSeverity.name || ''} // Ensure value is always a string
                                            >
                                                <Checkbox
                                                    checked={checkedAlarmsSeverity.find(profile => profile.name === alarmSeverity.name)?.selected || false}
                                                    onChange={(e) => handleChangeAlarmsSeverityCheckbox(e, checkedAlarmsSeverity.findIndex(profile => profile.name === alarmSeverity.name))}
                                                />
                                                <ListItemText primary={alarmSeverity.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-customers-label">Autoridades</InputLabel>
                                    <Select
                                        labelId="select-authority-label"
                                        id="select-authority"
                                        multiple
                                        value={checkedAuthorities.filter(item => item.selected).map(item => item.displayName)}
                                        input={<OutlinedInput label="Autoridades" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    >
                                        {authorities?.data?.map((authority : any) => (
                                            <MenuItem
                                                key={authority.id}
                                                value={authority.displayName || ''} // Ensure value is always a string
                                            >
                                                <Checkbox
                                                    checked={checkedAuthorities.find(profile => profile.displayName === authority.displayName)?.selected || false}
                                                    onChange={(e) => handleChangeAuthorityCheckbox(e, checkedAuthorities.findIndex(profile => profile.displayName === authority.displayName))}
                                                />
                                                <ListItemText primary={authority.displayName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} >

                            <Button
                                    variant="contained"
                                    size={'small'}
                                    disabled={!selectedPowerStation}
                                    endIcon={<AddCircleIcon/>}
                                    onClick={() => {
                                        if (tempRow) {
                                            setRows(prevRows => [...prevRows, tempRow]);
                                            setTempRow(null);
                                            setCheckedAuthorities(authorities => authorities.map(authority => ({ ...authority, selected: false })));
                                            setCheckedAlarmsSeverity(severities => severities.map(severity => ({ ...severity, selected: false })));
                                            setCheckedSkids(skids => skids.map(skid => ({ ...skid, selected: false })));
                                            setCheckedPowerStations(powerStations => powerStations.map(powerStation => ({ ...powerStation, selected: false })));
                                            setCheckedEquipments(equipments => equipments.map(equipment => ({ ...equipment, selected: false })));
                                            setCheckedTeleObjects(teleObjects => teleObjects.map(teleObject => ({ ...teleObject, selected: false })));
                                        }
                                    }}
                                >
                                    Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </ACard>
            </Box>

            <Box marginTop={10}>
                <ACard>
                    <ADataGrid
                        columns={columnsNotificationGroupItemGrid}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <span></span>
                                <IconButton name="delete" size={"small"} aria-label="Delete" onClick={(value) => handleDeleteNotificationGroupItem(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete />
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar essa notificação de grupo item?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>
        </>
    )
}

export default function NotificationGroupPageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <NotificationGroupFormPage />
        </QueryClientProvider>
    )
}