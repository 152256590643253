import {ACard} from "@atiautomacao/ati-ui-library";
import * as XLSX from "xlsx";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";
import {
    deleteMultipleEntities,
    deleteSingleEntity,
    saveConfiguration
} from "../../Reports/TeleObjectsReport/types/utils/ApiUtils";
import {useRef, useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import {AddCircle, Download} from "@mui/icons-material";
import * as React from "react";
import {downloadFile} from "../../../Utils/DownloadUtils";
import {useSnackbar} from "notistack";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import {LoadingButton} from "@mui/lab";


interface PowerStationDetails {
    id?: any;
    address: string;
    state?: any;
    zipCode: string;
    phone1: string;
    adminName: string;
    adminEmail: string;
    cityEntity: City;
    latitude: number;
    longitude: number;
}

interface EquipmentType {
    id?: any;
    name: string;
}

interface City {
    id?: any;
    name: string;
    state?: any;
}

interface EquipmentClass {
    id?: any;
    name: string;
}

interface Equipment {
    id?: any;
    name: string;
    equipmentType: EquipmentType | undefined;
    skidName: string;
    equipmentClass: EquipmentClass | undefined;
    ets: number;
    etsStatus: string;
    utrIpAddress: string;
    powerStation: any;
}

interface Skid {
    id?: any;
    name: string;
    powerStation: any;
    equipmentList: Equipment[];
}

interface PowerStation {
    id?: any;
    name: string;
    shortName: string;
    adminName: string;
    adminEmail: string;
    syncOperationMode: string;
    powerCapacityNominal: number;
    numberOfUG: number;
    expectedGeneratedEnergy: number;
    expectedYield: number;
    expectedPerformanceRatio: number;
    expectedIrradiance: number;
    meterInterval: number;
    inverterInterval: number;
    maxPower: number;
    moduleTemperatureCoefficient: number;
    operationStartedAt: string;
    status?: any;
    powerStationDetails: PowerStationDetails;
    skidList: Skid[];
    brokerList: Broker[];
}

interface ExpectedData {
    id?: any;
    date: string;
    energy: number;
    performanceRatio: number;
    irradiance: number;
    powerStation: any;
}

interface Broker {
    id?: any;
    name: string;
    ipAddress: string;
    port: number;
    txQueue: string;
    rxQueue: string;
    notificationQueue: string;
    powerStation: any;
}

export const PowerStationExcelPage = () => {
    const {enqueueSnackbar} = useSnackbar();

    const allowedExtensions = ["csv", "xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const hiddenFileInput = useRef(null);
    const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownload, setIsDownload] = useState(false);
    const [steps, setSteps] = useState<string[]>([]);
    const [errorSteps, setErrorSteps] = useState<string[]>([]);

    const handleClick = () => {
        // @ts-ignore
        hiddenFileInput.current.click();
    };

    const handleDownload = () => {
        const url = '/api/power-station/download/excel';
        const params = {}; //in this case, params is empty
        const filename = 'relatorio.xlsx';
        setIsDownload(true)
        downloadFile(url, params, filename,
            () => {
                enqueueSnackbar("Sucesso ao baixar o Template", {variant: 'success'});
                setIsDownload(false)
            },
            (error) => {
                enqueueSnackbar("Erro ao baixar o Template, contacte um dos administradores do sistema", {variant: 'error'});
                setIsDownload(false)
            }
        );

    };

    const convertExcelDateToJSDate = (serial: number) => {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = serial - Math.floor(serial) + 0.0000001;

        let total_seconds = Math.floor(86400 * fractional_day);

        const seconds = total_seconds % 60;

        total_seconds -= seconds;

        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    };

    const processUsinaSheet = (jsonData: any, powerStation: PowerStation) => {
        let rowsValue: string[] = [];
        jsonData.forEach((row: any) => {
            rowsValue.push(row["Valores"]);
        });
        powerStation.name = rowsValue[0];
        powerStation.shortName = rowsValue[1];
        powerStation.adminName = rowsValue[2];
        powerStation.adminEmail = rowsValue[3];
        powerStation.syncOperationMode = rowsValue[4];
        powerStation.powerCapacityNominal = Number(rowsValue[5]);
        powerStation.numberOfUG = Number(rowsValue[6]);
        powerStation.expectedGeneratedEnergy = Number(rowsValue[7]);
        powerStation.expectedYield = Number(rowsValue[8]);
        powerStation.expectedPerformanceRatio = Number(rowsValue[9]);
        powerStation.expectedIrradiance = Number(rowsValue[10]);
        powerStation.meterInterval = Number(rowsValue[11]);
        powerStation.inverterInterval = Number(rowsValue[12]);
        powerStation.maxPower = Number(rowsValue[13]);
        powerStation.moduleTemperatureCoefficient = Number(rowsValue[14]);
        powerStation.operationStartedAt = LocalDateTimeFormatISO(convertExcelDateToJSDate(Number(rowsValue[15])));
    };

    const processDetalhesDaUsinaSheet = (jsonData: any, powerStation: PowerStation, cities: City[]) => {
        let rowsValue: string[] = [];
        jsonData.forEach((row: any) => {
            rowsValue.push(row["Valores"]);
        });
        let cityId = cities?.find(item => item?.name === rowsValue[4]);
        if (cityId) {
            powerStation.powerStationDetails = {
                id: null,
                address: rowsValue[0], // Endereço
                state: null,
                zipCode: rowsValue[5], // CEP
                phone1: rowsValue[3], // Telefone
                adminName: rowsValue[1], // Nome do Administrador
                adminEmail: rowsValue[2], // E-mail do Administrador
                cityEntity: {
                    id: cityId.id,
                    name: rowsValue[4],
                    state: {
                        id: null,
                        name: null,
                        abvname: null,
                        country: null,
                        region: null
                    }
                },
                latitude: Number(rowsValue[6]), // Latitude
                longitude: Number(rowsValue[7]) // Longitude
            };
        }
    };

    const processSkidSheet = (jsonData: any, powerStation: PowerStation) => {
        jsonData.forEach((row: any) => {
            let skid: Skid = {
                id: null,
                name: row["Nome do Skid:"],
                powerStation: null,
                equipmentList: []
            }
            powerStation.skidList.push(skid);
        });
    }


    const processEquipamentSheet = (jsonData: any, powerStation: PowerStation, equipmentsClasses: EquipmentClass[], equipmentsType: EquipmentType[], unattachedEquipments: Equipment[]) => {
        jsonData.forEach((row: any) => {
            let equipments = {
                id: null,
                name: row["Nome do Equipamento:"],
                utrIpAddress: row["Endereço IP da UTR:"],
                ets: Number(row["ETS:"]),
                etsStatus: row["ETS Status:"],
                equipmentClass: equipmentsClasses.find((item: any) => item.name === row["Classe de Equipamento:"]),
                equipmentType: equipmentsType.find((item: any) => item.name === row["Tipo de Equipamento:"]),
                skidName: row["Skid:"],
                powerStation: null,
            };

            if (row["Skid:"] === undefined) {
                unattachedEquipments.push(equipments);
            } else {
                powerStation.skidList.forEach((skid: Skid) => {
                    if (skid.name === row["Skid:"]) {
                        skid.equipmentList.push(equipments);
                    }
                });
            }
        });
    };

    const processExpectedDataSheet = (jsonData: any, expectedDataList: ExpectedData[]) => {
        jsonData.forEach((row: any) => {
            let expectedData: ExpectedData = {
                id: null,
                powerStation: null,
                date: LocalDateTimeFormatISO(convertExcelDateToJSDate(row["Data"])),
                energy: Number(row["Energia Esperada MWh"]),
                performanceRatio: Number(row["PR Esperado %"]),
                irradiance: Number(row["Irradiância Esperada kWh/m²"]),
            }
            expectedDataList.push(expectedData);
        });
    };

    const processBrokerSheet = (jsonData: any, powerStation: PowerStation) => {
        jsonData.forEach((row: any) => {
            let broker: Broker = {
                id: null,
                ipAddress: row["Endereço de IP:"],
                name: row["Nome do Broker:"],
                notificationQueue: row["Fila de Notificação:"],
                port: Number(row["Porta:"]),
                rxQueue: row["Fila RX:"],
                txQueue: row["Fila TX:"],
                powerStation: null
            }
            powerStation.brokerList.push(broker)
        });
    };

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const handleFileChange = (e: any) => {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];

            if (!allowedExtensions.includes(fileExtension)) {
                return;
            }
            const file = e.target.files[0];

            if (file) {
                // @ts-ignore
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target?.result) {
                        const data = new Uint8Array(e.target.result as ArrayBuffer);
                        const workbook = XLSX.read(data, {type: 'array'});

                        let sheetsData: any = {};

                        let powerStation: PowerStation = {
                            id: null,
                            name: "",
                            shortName: "",
                            adminName: "",
                            adminEmail: "",
                            syncOperationMode: "R",
                            powerCapacityNominal: 0,
                            numberOfUG: 0,
                            expectedGeneratedEnergy: 0,
                            expectedYield: 0,
                            expectedPerformanceRatio: 0,
                            expectedIrradiance: 0,
                            meterInterval: 0,
                            inverterInterval: 0,
                            maxPower: 0,
                            moduleTemperatureCoefficient: 0,
                            operationStartedAt: "",
                            status: {id: 1, name: null},
                            powerStationDetails: {
                                id: null,
                                address: "",
                                state: null,
                                zipCode: "",
                                phone1: "",
                                adminName: "",
                                adminEmail: "",
                                cityEntity: {
                                    id: 2,
                                    name: "null",
                                    state: {
                                        id: 1,
                                        name: null,
                                        abvname: null,
                                        country: null,
                                        region: null
                                    }
                                },
                                latitude: 0,
                                longitude: 0
                            },
                            skidList: [],
                            brokerList: []
                        };
                        let equipmentsType: EquipmentType[] = [];
                        const sheet = workbook.Sheets["Tipo de Equipamento"];
                        const sheetEquipmentTypeData = XLSX.utils.sheet_to_json(sheet);

                        sheetEquipmentTypeData.forEach((row: any) => {
                            equipmentsType.push({
                                id: row["ID"],
                                name: row["Nome do Tipo de Equipamento"]
                            });
                        });

                        let equipmentsClasses: EquipmentClass[] = [];
                        const sheetNameOfEquipmentClass = workbook.Sheets["Classe de Equipamento"];
                        const sheetEquipmentClassData = XLSX.utils.sheet_to_json(sheetNameOfEquipmentClass);


                        sheetEquipmentClassData.forEach((row: any) => {
                            equipmentsClasses.push({
                                id: row["ID"],
                                name: row["Nome da Classe de Equipamento"]
                            });
                        });

                        let expectedDataList: ExpectedData[] = [];
                        let unattachedEquipments: Equipment[] = [];


                        let cities: City[] = [];
                        const sheetNameOfCities = workbook.Sheets["Cidade"];
                        const sheetCitiesData = XLSX.utils.sheet_to_json(sheetNameOfCities);

                        sheetCitiesData.forEach((row: any) => {
                            cities.push({
                                id: row["ID"],
                                name: row["Nome da Cidade"]
                            })
                        })

                        workbook.SheetNames.forEach((sheetName) => {
                            const worksheet = workbook.Sheets[sheetName];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            sheetsData[sheetName] = jsonData;

                            if (sheetName === "Usina") {
                                processUsinaSheet(jsonData, powerStation);
                            } else if (sheetName === "Detalhes da Usina") {
                                processDetalhesDaUsinaSheet(jsonData, powerStation, cities);
                            } else if (sheetName === "Skid") {
                                processSkidSheet(jsonData, powerStation);
                            } else if (sheetName === "Equipamento") {
                                processEquipamentSheet(jsonData, powerStation, equipmentsClasses, equipmentsType, unattachedEquipments);
                            } else if (sheetName === "Dados Esperados") {
                                processExpectedDataSheet(jsonData, expectedDataList);
                            } else if (sheetName === "Broker") {
                                processBrokerSheet(jsonData, powerStation);
                            }
                        });

                        const powerStationSaveDTO = {
                            powerStation: powerStation,
                            expectedDataList: null,
                        }
                        handleSaveBackend(powerStationSaveDTO, expectedDataList, unattachedEquipments)
                            .then(() => {
                                console.log('Dados salvos com sucesso!');
                            });
                    }
                };
                reader.readAsArrayBuffer(file);
            }
        }
    };

    const handleSaveBackend = async (powerStationSaveDTO: any, expectedDataList: ExpectedData[], unattachedEquipments: Equipment[]) => {
        let savedPowerStationId: number | null = null;
        let savedExpectedDataIds: number[] = [];
        let savedUnattachedEquipmentIds: number[] = [];
        let savedSkidEquipmentIds: number[] = [];

        try {
            // Save the powerStation
            setIsLoading(true);
            setLoadingIndex(0);
            setSteps(prevSteps => [...prevSteps, "Cadastrando Usina, Skids e Brokers!"]);
            await delay(2000);
            const response = await saveConfiguration(
                "/api/power-station",
                powerStationSaveDTO
            );
            savedPowerStationId = response.data.powerStation.id;
            const savedSkidList = response.data.powerStation.skidList;
            setLoadingIndex(1);
            setSteps(prevSteps => [...prevSteps, "Usina, Skids e Brokers Cadastrados com Sucesso!"]);
            await delay(2000);
            // Att the list of expectedData, adding an new object powerStation with id save
            const updatedExpectedDataList = expectedDataList.map(expectedData => ({
                ...expectedData,
                powerStation: {id: savedPowerStationId}
            }));
            setLoadingIndex(2);
            setSteps(prevSteps => [...prevSteps, "Cadastrando dados esperados..."]);
            await delay(2000);
            // Save updated expectedDataList and collect their IDs
            const expectedDataResponse = await saveConfiguration(
                "/api/expected-data",
                updatedExpectedDataList);
            savedExpectedDataIds = expectedDataResponse.data.map((data: ExpectedData) => data.id);
            setLoadingIndex(3);
            setSteps(prevSteps => [...prevSteps, "Dados Esperados Cadastrados com Sucesso!"]);
            await delay(2000);


            // Att the unattachedEquipments with id's of skids
            const updatedUnattachedEquipments = unattachedEquipments.map(equipment => {
                const matchedSkid = savedSkidList.find((skid: Skid) => skid.name === equipment.skidName);
                return {
                    ...equipment,
                    skid: matchedSkid ? {id: matchedSkid.id, name: matchedSkid.name} : null,
                    powerStation: {id: savedPowerStationId}
                };
            });
            setLoadingIndex(4);
            setSteps(prevSteps => [...prevSteps, "Salvando equipamentos com e sem Skids..."]);
            await delay(2000);
            // Save updated unattachedEquipments and collect their IDs
            const unattachedEquipmentResponse = await saveConfiguration(
                "/api/equipment/list-equipments/without-skid",
                updatedUnattachedEquipments);
            savedUnattachedEquipmentIds = unattachedEquipmentResponse.data.map((equipment: Equipment) => equipment.id);


            // Save the equipments associated with each skid
            const skidsWithEquipment = savedSkidList.map((skid: Skid) => ({
                ...skid,
                equipmentList: skid.equipmentList.map((equipment: Equipment) => ({
                    ...equipment,
                    skid: {id: skid.id, name: skid.name},
                    powerStation: {id: savedPowerStationId}
                })),
                powerStation: {id: savedPowerStationId}
            }));
            setLoadingIndex(4);
            await delay(2000);
            const skidEquipmentResponse = await saveConfiguration(
                "/api/equipment/list-equipments",
                skidsWithEquipment.flatMap((skid: Skid) => skid.equipmentList));
            savedSkidEquipmentIds = skidEquipmentResponse.data.map((equipment: Equipment) => equipment.id);
            setLoadingIndex(5);
            setSteps(prevSteps => [...prevSteps, "Equipamentos cadastrados com sucesso!"]);
            await delay(2000);
            setLoadingIndex(null);
            enqueueSnackbar("Cadastro de Usina concluído com sucesso!", {variant: 'success'});
        } catch (error) {
            await handleRollback(savedPowerStationId, savedExpectedDataIds, savedUnattachedEquipmentIds, savedSkidEquipmentIds);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRollback = async (powerStationId: number | null, expectedDataIds: number[], unattachedEquipmentIds: number[], equipmentsWithSkid: number[]) => {
        if (equipmentsWithSkid.length > 0) {
            try {
                await deleteMultipleEntities("/api/equipment/delete-all", equipmentsWithSkid);
                setErrorSteps(prevSteps => [...prevSteps, "Rollback do cadastro de equipamentos com Sucesso!"]);
                await delay(2000);
            } catch (rollbackError) {
                enqueueSnackbar("Erro ao realizar rollback dos equipamentos associados aos skids", {variant: 'error'});
            }
        }

        if (unattachedEquipmentIds.length > 0) {
            try {
                await deleteMultipleEntities("/api/equipment/delete-all", unattachedEquipmentIds);
                setErrorSteps(prevSteps => [...prevSteps, "Rollback do cadastro de equipamentos sem Skid com Sucesso!"]);
                await delay(2000);
            } catch (rollbackError) {
                enqueueSnackbar("Erro ao realizar rollback dos equipamentos sem skid", {variant: 'error'});
            }
        }

        if (expectedDataIds.length > 0) {
            try {
                await deleteMultipleEntities("/api/expected-data/delete-all", expectedDataIds);
                await delay(2000);
                setErrorSteps(prevSteps => [...prevSteps, "Rollback do cadastro de dados esperados com Sucesso!"]);
            } catch (rollbackError) {
                enqueueSnackbar("Erro ao realizar+- rollback dos dados esperados", {variant: 'error'});
            }
        }

        if (powerStationId) {
            try {
                await deleteSingleEntity("/api/power-station", powerStationId);
                setErrorSteps(prevSteps => [...prevSteps, "Rollback do cadastro de skids com Sucesso!"]);
                await delay(2000);
                setErrorSteps(prevSteps => [...prevSteps, "Rollback do cadastro de brokers com Sucesso!"]);
                await delay(2000);
                setErrorSteps(prevSteps => [...prevSteps, "Rollback do cadastro de Usina com Sucesso!"]);
                enqueueSnackbar("Cadastro não concluído, verifique os campos válidos do excel ou contate um administrador do sistema!", {variant: 'error'});
            } catch (rollbackError) {
                enqueueSnackbar("Erro ao seguir o fluxo de salvar usina, contacte um dos administradores do sistema", {variant: 'error'});
            }
        }
    };

    return (
        <>
            <Box>
                {
                    isDownload ?
                        <LoadingButton
                            loading
                            loadingPosition="end"
                            endIcon={<SaveIcon sx={{fontSize: 20}}/>}
                        >
                            Carregando..
                        </LoadingButton>
                        :
                        <Button
                            variant="contained"
                            size={"small"}
                            color={"primary"}
                            endIcon={<Download/>}
                            onClick={handleDownload}
                            style={{marginLeft: 5}}
                        >
                            Template
                        </Button>
                }
                <Button
                    variant="contained"
                    size={"small"}
                    color={"primary"}
                    endIcon={<AddCircle/>}
                    onClick={handleClick}
                    style={{marginLeft: 5}}
                >
                    Importar
                </Button>
                <input
                    type="file"
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                    style={{display: 'none'}}/>
            </Box>
            <br/>

            {isLoading ? (
                <ACard title='Cadastro de Usina via Excel'>
                    <h2>Aguarde um pouco, por favor..</h2>
                    <ul>
                        {steps.map((step, index) => (
                            <li key={index.valueOf()}>
                                {step} {loadingIndex === index && <CircularProgress color="success" size={10}/>}
                            </li>
                        ))}
                        {errorSteps.map((error, index) => (
                            <li key={`error-${index.valueOf()}`} style={{color: 'red'}}>
                                {error}
                            </li>
                        ))}
                    </ul>
                </ACard>
            ) : (
                <ACard title='Cadastro de Usina via Excel'>
                    <div>
                    </div>
                </ACard>
            )}
        </>
    )

}