import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";

const apiUrl = "/api/notification-group-items";

const fetchNotificationGroupItems = async (pagedSearchParams: PagedSearchParams, notificationGroupId: string) => {
    try {
        // Converta pagedSearchParams para parâmetros URL e adicione notificationGroupId
        const urlParams = new URLSearchParams(pagedSearchParams.toURLSearchParams());
        if(notificationGroupId !== undefined){
            urlParams.append('notificationGroupId', notificationGroupId);
        }

        return await axios
            .get(`${apiUrl}/search`, {
                params: urlParams
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching notification group item data:", error);
        return Promise.reject(error);
    }
};

export const NotificationGroupItemReactQuery = (pagedSearchParams: PagedSearchParams, notificationGroupId: string) => {
    return useQuery(["notificationGroupItems", pagedSearchParams], () => fetchNotificationGroupItems(pagedSearchParams, notificationGroupId));
}

const deleteNotificationGroupItem = async (id: number) => {
    try {
        const response = await axios.delete(`/api/notification-group-items/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting notification group item:", error);
        throw error;
    }
};

export const DeleteNotificationGroupItemReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: any) => {
            return deleteNotificationGroupItem(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Grupo de Notificação excluído com sucesso!", {variant: "success"});
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir notificação de grupo: ${error.message}`, {variant: "error"});
        }
    });
};