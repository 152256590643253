import {useQuery} from "react-query";
import axios from "axios";


const apiUrl = "api/skid";


const fetchSkids = async (powerStationId: number) => {
    try {
        // Construir a URL com base no parâmetro powerStationId
        const url = `${apiUrl}/find-all-skids/${powerStationId}`;

        // Fazer a requisição
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllSkidsByPowerStationIdReactQuery = (powerStationId: number | undefined) => {
    return useQuery(
        ['find-all-skids', powerStationId], // Key for caching and invalidation
        () => {
            if (powerStationId) {
                return fetchSkids(powerStationId);
            }
            return [];

        }
    );
};


