import {useQuery} from "react-query";
import axios from "axios";



const apiUrl = "/api/customer";

const fetchCustomers = async () => {
    const response = await axios.get(`${apiUrl}/all`);
    return response.data;
};

export const CustomerReactQuery = () => {
    return useQuery("customers", fetchCustomers);
}