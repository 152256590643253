import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormGroup,
    Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {login, logout, UserEntity} from "../../Config/Reducers/AuthenticationReducer";
import {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import logoAzul from "../../Assets/logo-azul.png"
import {useSnackbar} from "notistack";
import {useAppDispatch, useAppSelector} from "../../Config/Hooks";
import themeDefaults from "../../Shared/Theme/ThemeDefaults";

export default function LoginPage(){

    const { enqueueSnackbar } = useSnackbar();
    const [entity, setEntity] = useState(new UserEntity());
    const dispatch = useAppDispatch();
    // const isLoading = useAppSelector((state) => state.authentication.loading);
    const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
    const account = useAppSelector((state) => state.authentication.account);
    const loginError = useAppSelector(state => state.authentication.loginError);
    const errorMessage = useAppSelector(state => state.authentication.errorMessage);

    const handleLogin = () => {
        let user = new UserEntity();
        user.username = entity.username;
        user.password = entity.password;
        user.rememberMe= entity.rememberMe;
        dispatch(login(user));
    };

    const handleClose = () => {
        // sair
    };
    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setEntity((oldValue) => {
            return {
                ...oldValue,
                [name]: value
            }
        })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    }

    const handleRememberMe = (event) => {
        let name = event.target.name;
        let value = event.target.checked;
        setEntity((oldValue) => {
            return {
                ...oldValue,
                [name]: value
            }
        })
    }
    useEffect(() => {
        if( loginError) {
            if(errorMessage.toLowerCase().indexOf("timeout") !== -1 ||
                errorMessage.toLowerCase().indexOf("network") !== -1) {
                console.log("Falha de comunicação!", errorMessage);
                enqueueSnackbar("Falha de comunicação!", {variant: 'error'});
            }
            else if(errorMessage.toLowerCase().indexOf("500") !== -1){
                console.log("Falha ao logar. Contate a administrador do sistema.", errorMessage);
                enqueueSnackbar("Falha ao logar. Contate o administrador do sistema.", {variant: 'error'});
            }
            else if(errorMessage.toLowerCase().indexOf("503") !== -1){
                console.log("Serviço de login indisponível.", errorMessage);
                enqueueSnackbar("Serviço de login indisponível.", {variant: 'error'});
            }
            else if(errorMessage.toLowerCase().indexOf("204") !== -1){
                console.log("Usuário não encontrado.", errorMessage);
                enqueueSnackbar("Usuário não encontrado.", {variant: 'error'});
            }
            else if (errorMessage.toLowerCase().indexOf("401") !== -1){
                console.log("Usuário ou senha inválidos.", errorMessage);
                enqueueSnackbar("Usuário ou senha inválidos.", {variant: 'error'});
            }
            else {
                console.log("Erro ao logar!", errorMessage);
                enqueueSnackbar(errorMessage, {variant: 'error'});
            }
            dispatch(logout());
        }
    },[isAuthenticated,loginError, enqueueSnackbar, errorMessage, dispatch]);

    if (isAuthenticated === true && account && account.name !== undefined && account.name.length > 0) {
        return <Navigate to="/dashboard/overview" />;
    }
    return (
        <Container maxWidth="xs" id="login-form" style={{
            textAlign: "center",
            // backgroundColor: "blue"
        }}>
            <Card
                sx={{marginTop: 3, minWidth: 275, borderRadius: themeDefaults.borderRadius}}>
                <CardContent style={{textAlign: "center"}}>
                    <img alt={"Logo Azul"} src={logoAzul} style={{height: 60, width: "auto"}}/><br/>
                    <Typography variant="p" style={{marginTop: 7}}>
                        Sistema Supervisório de Geração Distribuida</Typography>
                    <Divider/>
                    <br/>
                    <FormGroup>
                        <TextField
                            required
                            id="username"
                            name="username"
                            label="Usuário"
                            placeholder="Digite seu login"
                            variant="standard"
                            // value={entity.username}
                            size={"small"}
                            onChange={handleChange}
                            onKeyUp={handleKeyPress}
                        />
                        <TextField
                            required
                            id="password"
                            name="password"
                            type="password"
                            label="Senha"
                            placeholder="Digite sua senha"
                            variant="standard"
                            // value={entity.password}
                            size={"small"}
                            onChange={handleChange}
                            onKeyUp={handleKeyPress}
                        />
                        <FormControlLabel
                            label="Manter logado"
                            control={<Checkbox checked={entity.rememberMe}

                                               id="rememberMe"
                                               name="rememberMe"
                                               onChange={handleRememberMe}/>}
                        />
                    </FormGroup>
                </CardContent>
                <CardActions style={{textAlign: "right"}}>
                    <Button onClick={handleClose}>Esqueceu a senha?</Button>
                    <Button onClick={handleLogin}>Entrar</Button>
                </CardActions>
            </Card>
            <br/>


        </Container>
    );
}
