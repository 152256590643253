import {useQuery} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";



const apiUrl = "api/equipment";


const fetchTeleCommands = async (equipmentIds: number[], pagedSearchParams: PagedSearchParams) => {

    try {
        const queryParams = new URLSearchParams();
        if (equipmentIds && equipmentIds.length > 0) {
            queryParams.append('equipmentIds', equipmentIds.join(','));
        }

        if (pagedSearchParams) {
            const extraParams = pagedSearchParams.toURLSearchParams();
            extraParams.forEach((value, key) => queryParams.append(key, value));
        }

        const newApiUrl = `${apiUrl}/tele-commands?${queryParams.toString()}`;

        const response = await axios.get(newApiUrl);
        return response.data;
    } catch (error: any) {
        console.error("Error fetching data:", error);
        throw new Error("Error fetching data: " + error.message);
    }
};

export const TeleCommandReactQuery = (equipmentIds: number[], pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams, equipmentIds],
        queryFn: () => {
            return fetchTeleCommands(equipmentIds, pagedSearchParams);
        },
    });
};
