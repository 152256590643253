import React, {FunctionComponent, useEffect, useState} from "react";
import {
    Grid
} from "@mui/material";

import {useSnackbar} from "notistack";
import GenerationEnergyChart from "./components/GenerationEnergyChart";
import CardList from "./components/CardList";
import { FindTeleObjectInverterReactQuery} from "./InverterReactQuery";
import InverterNodes from "./components/InverterNodes";
import SelectInverter from "./components/SelectInverter";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import PowerIrradianceInverterChart from "./components/PowerIrradianceInverterChart";

export interface EquipmentProps {
    id: number;
    name: string;
    equipmentType:any
}
export interface SkidProps {
    id: number;
    name: string;
    equipmentList: EquipmentProps[];
}
const InvertersPage: FunctionComponent<{powerPlant: any}> = ({powerPlant}) => {

    const mutate = FindTeleObjectInverterReactQuery();
    const [inverterBySkid,setInverterBySkid]  = useState<SkidProps[]>([]);
    const [powerDataList,setPowerDataList]  = useState<any[]>([]);
    const [sideDCDataList,setSideDCDataList]  = useState<any[]>([]);
    const [sideACDataList,setSideACDataList]  = useState<any[]>([]);
    const [operatingDataList,setOperatingDataList]  = useState<any[]>([]);
    const [energyDataList,setEnergyDataList]  = useState<any[]>([]);
    const [solarimetricStationDataList,setSolarimetricStationDataList]  = useState<any[]>([]);
    const [statusDataList,setStatusDataList]  = useState<any[]>([]);
    const [nodeWithElements,setNodeWithElements]  = useState<any>();
    const [selectedId,setSelectedId]  = useState<number>(-1);
    const [selectedTitle,setSelectedTitle]  = useState<string>("");

    useEffect(() => {
        let skidList: SkidProps[] = [];
        powerPlant.skidList.forEach((skid: any) => {
            let equipmentByInverterList: EquipmentProps[]=[];
            skid.equipmentList.map((eqp: any) => {
                if(eqp.equipmentType && eqp.equipmentType?.id === 2){
                    equipmentByInverterList.push({
                        id: eqp.id,
                        name: eqp.name,
                        equipmentType: eqp.equipmentType,
                    } )
                }
            });
            skidList.push({
                id: skid.id,
                name: skid.name,
                equipmentList: equipmentByInverterList,
            } )
        });
        setInverterBySkid(skidList);
    }, [powerPlant.id]);

    useEffect(() => {
        if(selectedId !== -1 && powerPlant.id){
            mutate.mutate({equipmentId: selectedId, powerStationId: powerPlant.id},
                {
                    onSuccess: (data) => {
                        setPowerDataList(data?.data?.powerDataList || []);
                        setSideDCDataList(data?.data?.sideDCDataList || []);
                        setSideACDataList(data?.data?.sideACDataList || []);
                        setOperatingDataList(data?.data?.operatingDataList || []);
                        setEnergyDataList(data?.data?.energyDataList || []);
                        setStatusDataList(data?.data?.statusDataList || []);
                        setSolarimetricStationDataList(data?.data?.solarimetricStationDataList || []);
                        setNodeWithElements(data?.data?.nodeWithElements || {});
                    },
                    onError: (error: any) => {
                        enqueueSnackbar(`Erro ao carregar dados: ${error.message}`, { variant: "error" });
                    }
                });
        } else {
            setPowerDataList([]);
            setSideDCDataList([]);
            setSideACDataList([]);
            setOperatingDataList([]);
            setEnergyDataList([]);
            setStatusDataList([]);
            setSolarimetricStationDataList([]);
            setNodeWithElements({});
        }

    }, [powerPlant.id, selectedId]);

    const { enqueueSnackbar } = useSnackbar()
    const handleSelectedElementMenu = (value: any, title: string) => {
        if(!value || !title){
            setSelectedId(-1);
            setSelectedTitle("Selecione um Inversor");
        } else {
            setSelectedId(value);
            setSelectedTitle(title);
        }
    }
    return (
        <Grid container>
            <Grid container xs={12} spacing={2}>
                <Grid item md={3} xs={12} >
                    <SelectInverter onSelectedElement={handleSelectedElementMenu} data={inverterBySkid}/>
                    <CardList height={248} data={sideDCDataList} label="Lado DC"  isSuccess={mutate.isSuccess}/>
                    <CardList height={386} data={sideACDataList} label="Lado AC"  isSuccess={mutate.isSuccess}/>
                </Grid>
                <Grid item md={6} xs={12} >
                    <InverterNodes
                        statusData={statusDataList}
                        solarimetricStation={solarimetricStationDataList}
                        entities={nodeWithElements}
                        isSuccess={mutate.isSuccess}
                        title={selectedTitle}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <CardList height={truncateNumber(280)} data={powerDataList} label="Dados de Potência" isSuccess={mutate.isSuccess}/>
                    <CardList height={truncateNumber(163)} data={energyDataList} label="Dados de Energia" isSuccess={mutate.isSuccess}/>
                    <CardList height={truncateNumber(239)} data={operatingDataList} label="Dados de Funcionamento" isSuccess={mutate.isSuccess}/>
                </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                    <GenerationEnergyChart powerPlantId={powerPlant.id} equipmentId={selectedId}/>
                </Grid>
                <br/>
                <Grid item xs={12} md={6}>
                    <PowerIrradianceInverterChart powerPlant={powerPlant} equipmentId={selectedId}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InvertersPage;