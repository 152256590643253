import * as React from "react";
import {AppBar, Box, Button, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {Link} from "react-router-dom";
import {AddCircle} from "@mui/icons-material";
import {useAppSelector} from "../../../Config/Hooks";

export default function CountryHeaderMenu() {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openHeaderMenu = useAppSelector((state: any) => state.countryMenu.header.open);
    const theme = useTheme();

    if (isAuthenticated && openHeaderMenu ) return (
        <AppBar style={{top: 64}}>
            <Toolbar
                sx={{
                    marginLeft: {xs: "initial", md: isAuthenticated ? openMainMenu ? `${400}px` : `${0}px` : 'initial'},
                    backgroundColor: theme.palette.background.default
                }}
            >
                <Box sx={{ flexGrow: 1}}></Box>
                <Button size={"small"} variant="contained" component={Link} to='/settings/country/new' endIcon={<AddCircle />}>
                    New
                </Button>
            </Toolbar>
        </AppBar>
    );

    return null;
}
