import React, {useEffect, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {GenerationEntity, getEntities} from "./GenerationPRChartReducer.reducer";
import {AChartX, Period, PeriodSelectorGroupProps, Skeleton} from "@atiautomacao/ati-ui-library";
import {checkDiffDays} from "../../../Utils/DataUitils";
import DataNotFound from "../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../Shared/Hooks/useInterval";
import {useTheme} from "@mui/material";

interface SeriesProps {
    name: string;
    station: any[];
    unity: string;
    value: number;
    data: any[];
    color?: string;
}

interface GenerationPRChartProps {
    period?: Period;
    periodIntervalGroup? : PeriodSelectorGroupProps;
}
export function GenerationPRChart(props: GenerationPRChartProps) {
    const theme = useTheme();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const generation: Array<GenerationEntity> = useAppSelector((state: RootState) => state.generationPRChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationPRChart);
    const dispatch = useAppDispatch();

    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(props.period ? props.period : initialPeriod);
    const [data, setData] = useState<Array<SeriesProps>>(new Array<SeriesProps>);

    useEffect(() => {
        if(props.period){
            setPeriod(props.period);
        }
    }, [props.period]);

    const callback = (args:any) => {

        let result : any = args.marker + data[args.dataIndex].value + data[args.dataIndex].unity + '<br />'
        let quantity:number = data[args.dataIndex].station.length

        for(let i=0; i< quantity; i++){
            result += data[args.dataIndex].station[i] + ': ' + data[args.dataIndex].data[i] + ' '+ data[args.dataIndex].unity + '<br />'
        }

        return result;
    }

    const dataSet = (group: string) => {
        let filteredData: Array<any> = [];
        filteredData = (generation.filter(el => el.group === group));

        return filteredData;
    }

    let groupGray = dataSet('#a1a1a1');
    let groupRed = dataSet('#ee6565');
    let groupOrange = dataSet('#fac858');
    let groupYellow = dataSet('#eee766');
    let groupGreen = dataSet('#91cc75');

    const colorPalette = data.map(el => el.color);

    const chartOption: any = {
        tooltip: {
            trigger: 'item',
            formatter: callback
        },
        legend: {
            type: 'scroll',
            orient: 'horizontal', // Alterado para orientação horizontal
            bottom: 5, // Posiciona a legenda abaixo do gráfico
            left: 'left', // Alinhar a legenda à esquerda
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                fontSize: 15,
                fontWeight: 'normal',
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            }
        },
        grid: {
            containLabel: true,
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                color: colorPalette,
                label: {
                    show: false,
                    position: 'center'
                },
                data:
                    data.map(el => ({
                        name: el.name,
                        station: el.station,
                        data: el.data,
                        value: el.value,
                        color: el.color
                    })),
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
            }
        ]
    }

    function findGeneratedPRDataByPeriod() {
        //
        setData([
            {
                //gray -> <0% e >100%
                station: groupGray.map(el => el.powerStationName),
                name: '<0% e >100%',
                data: groupGray.map(el => el.value),
                value: (groupGray.length * 100) / generation.length,
                unity: '%',
                color: '#a1a1a1'
            },
            {
                //red -> 0% a 50%
                station: groupRed.map(el => el.powerStationName),
                name: '0-50%',
                data: groupRed.map(el => el.value),
                value: (groupRed.length * 100) / generation.length,
                unity: '%',
                color: '#ee6565'
            },
            {
                //orange -> >50% a 60%
                station: groupOrange.map(el => el.powerStationName),
                name: '>50-60%',
                data: groupOrange.map(el => el.value),
                value: (groupOrange.length * 100) / generation.length,
                unity: '%',
                color: '#fac858'
            },
            {
                //yellow -> >60% a 80%
                station: groupYellow.map(el => el.powerStationName),
                name: '>60-70%',
                data: groupYellow.map(el => el.value),
                value: (groupYellow.length * 100) / generation.length,
                unity: '%',
                color: '#eee766'
            },
            {
                //green -> >80% a 100%
                station: groupGreen.map(el => el.powerStationName),
                name: '>70-100%',
                data: groupGreen.map(el => el.value),
                value: (groupGreen.length * 100) / generation.length,
                unity: '%',
                color: '#91cc75'
            }
        ]);
    }
    const fetchEntities = () => {
        if (checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            dispatch(getEntities(period));
        }
    };

    useEffect(() => {
        fetchEntities();
    },[period]);

    useInterval(() => {
        fetchEntities();
    }, 60000) // 5 minutes

    useEffect(() => {
        if(Array.isArray(generation) && generation.length > 0) {
            findGeneratedPRDataByPeriod();
        }else {
            setData([]);
        }
    },[generation]);

    let content;

    if (loading) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else {
        content = generation && generation.length > 0 ? (
            <AChartX
                key={`card-overview-pr-${openSubMenu}`}
                height={289}
                width={'100%'}
                option={chartOption}
                theme={theme.palette.mode}
            />
        ) : (
            <DataNotFound boxStyle={{ height: 289, width: '100%' }} />
        );
    }

    return (
        <>
            {content}
        </>
    );
}