import {useQuery} from "react-query";
import axios from "axios";
import {useSnackbar} from "notistack";

export interface Telemetry {
    id: number;
    name: string;
    unit: string;
    value: number[];
    time: string[];
}
export interface Telecommand {
    id: number;
    name: string;
    unit: string;
    value: number[];
    time: string[];
}
export interface Telesinal {
    id: number;
    name: string;
    value: number[];
    time: string[];
}
export interface EquipmentDetailsData {
    telemetryList: Telemetry[];
    telecommandList: Telecommand[];
    telesinalList: Telesinal[];

}
export interface TeleObjectDTO {
    startDateTime: string;
    stopDateTime:string;
    equipmentId: number;
    teleObjectList: number[];
}

interface Response {
    message: string;
    data: EquipmentDetailsData
}
const apiUrl = 'api/equipment';

const fetchData = async (teleObjectDTO: any): Promise<Response> => {
    try {
        const response = await axios.post(`${apiUrl}/tele-objects/`, teleObjectDTO, {
            timeout: 30000
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching echart data:", error);
        throw new Error("Error fetching echart data");
    }
}

export const EquipmentDetailsReactQuery = (teleObject: any) => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: ['fetchTeleObjectData', teleObject],
        queryFn: () => fetchData(teleObject),
        enabled: !!teleObject,
        retry: false,
        onError: (error) => {
            enqueueSnackbar("Erro ao gerar os gráficos", { variant: 'error' });
            console.error("Query Error:", error);
        }
    });
}