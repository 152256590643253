import * as React from 'react';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHardDrive} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation} from "react-router-dom";
import {SystemRoutes} from "../../../../Utils/RouteUtils";
import {Forward, SwapVert} from "@mui/icons-material";


const style = {
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
};

export default function SGDCommunicationMenu() {
    const location = useLocation();
    return (
        <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            <nav aria-label="main mailbox folders">
                <List>
                    <Link style={style.linkItem} to={SystemRoutes.DASHBOARD_OVERVIEW}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <SwapVert/>
                                </ListItemIcon>
                                <ListItemText primary="Requisição/Resposta"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.DASHBOARD_OVERVIEW}>
                        <ListItem disablePadding
                                  selected={location.pathname.includes("equipment") && !location.pathname.includes("class")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive}/>
                                </ListItemIcon>
                                <ListItemText primary="Configuração de SSS"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.COMMUNICATION_SENT_TELECOMMAND}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment-class")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Forward/>
                                </ListItemIcon>
                                <ListItemText primary="Envio de Telecomandos"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.DASHBOARD_OVERVIEW}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment-type")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive}/>
                                </ListItemIcon>
                                <ListItemText primary="Inibe/Habilita Telessinais"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.DASHBOARD_OVERVIEW}>
                        <ListItem disablePadding selected={location.pathname.includes("graph-element")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive}/>
                                </ListItemIcon>
                                <ListItemText primary="Inibe/Habilita Telemedidas"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={SystemRoutes.DASHBOARD_OVERVIEW}>
                        <ListItem disablePadding selected={location.pathname.includes("grafic_element")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive}/>
                                </ListItemIcon>
                                <ListItemText primary="Inibe/Habilita Equipamentos"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.DASHBOARD_OVERVIEW}>
                        <ListItem disablePadding
                                  selected={location.pathname.includes("tele-object") && !location.pathname.includes("Specification")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive}/>
                                </ListItemIcon>
                                <ListItemText primary="Pedido de Alarmes"/>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </List>
            </nav>
        </Box>
    );
}