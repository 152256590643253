import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {ChartConfigType} from "../../Shared/Types/chartConfigType";
import {AnalysisEquipmentType} from "../../Shared/Types/AnalysisEquipmentType";
import {subDays, subMonths, subWeeks, subYears} from "date-fns";
import {EChartType} from "../../Shared/Types/Echart/echart";


export interface AnalysisEntity {
    id: number | null,
    name: string,
    selected?:boolean,
    analysisEquipmentsList: Array<AnalysisEquipmentType>,
    chartConfigList:Array<ChartConfigType>
}

interface Response {
    message: string;
    data: AnalysisEntity
}

interface ResponseConfig {
    message: string;
    data: ChartConfigType[]
}

interface ResponseEChart {
    message: string;
    data: EChartType[]
}

interface ResponseChartConfig {
    message: string;
    data: EChartType
}
interface ResponseConfigurationEntity {
    message: string;
    data: {
        id: number,
        name: string,
        value: number
    }
}

const apiUrl = 'api/analysis';

const fetchEchart = async (chartConfigDTO: any): Promise<ResponseEChart> =>{
    try {
        return await axios
            .post<ResponseEChart>(`${apiUrl}/echart`, chartConfigDTO,{timeout:30000})
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
}
export const handleStartDateTimeToRecent = (value: number, groupingRecentByPeriod: string) => {
    let now = new Date();
    let newStartDateTime: Date | null = null;
    if(value === 0){
        return now;
    }
    if(groupingRecentByPeriod === 'none'){
        newStartDateTime = subDays(now, value);
        newStartDateTime.setHours(0);
        newStartDateTime.setMinutes(0);
        newStartDateTime.setSeconds(0);
    } else if(groupingRecentByPeriod === 'day'){
        newStartDateTime = subDays(now, value -1);
        newStartDateTime.setHours(0);
        newStartDateTime.setMinutes(0);
        newStartDateTime.setSeconds(0);
    } else if(groupingRecentByPeriod === 'week'){
        newStartDateTime = subWeeks(now, value);
        newStartDateTime.setHours(0);
        newStartDateTime.setMinutes(0);
        newStartDateTime.setSeconds(0);
    } else if(groupingRecentByPeriod === 'month'){
        newStartDateTime = subMonths(now, value);
        newStartDateTime.setHours(0);
        newStartDateTime.setMinutes(0);
        newStartDateTime.setSeconds(0);
    } else if(groupingRecentByPeriod === 'year'){
        newStartDateTime = subYears(now, value -1);
        newStartDateTime.setHours(0);
        newStartDateTime.setMinutes(0);
        newStartDateTime.setSeconds(0);
    }
    return newStartDateTime;
}

const fetchSequential = async (chartConfigId: number): Promise<ResponseChartConfig> => {
    console.log("-------------chartConfigId-------------",chartConfigId);
    
    try {
        return await axios
            .get<ResponseChartConfig>(`${apiUrl}/chart-config/${chartConfigId}`,{timeout:30000})
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
}

export const AnalysisHomeReactQuery = (analysisId: any) => {
    return useQuery({
        queryKey: ['data', analysisId],
        queryFn: () => {
           return fetchSequential(analysisId)
        },
        enabled: !!analysisId,
        retry: 2,
    })
}

const fetchConfiguration = async (analysisMaxNumber: string): Promise<ResponseConfigurationEntity> => {
    try {
        return await axios
            .get<ResponseConfigurationEntity>(`api/configuration/${analysisMaxNumber}`,{timeout: 30000})
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching configuration by name:", error);
        return Promise.reject(error);
    }
}

export const ConfigurationByNameReactQuery = (analysisMaxNumber: any) => {
    return useQuery({
        queryKey: ['data', analysisMaxNumber],
        queryFn: () => {
           return fetchConfiguration(analysisMaxNumber)
        },
        enabled: !!analysisMaxNumber
    })
}

const fetchAllChartConfig = async (analysisId: any): Promise<Response> =>{
    try {
        return await axios.get<Response>(`${apiUrl}/${analysisId}`,{timeout:30000})
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching analysis data:", error);
        return Promise.reject(error);
    }
}
export const AnalysisReactQuery = (analysisId: any) => {
    return useQuery({
        queryKey: ['data', analysisId],
        queryFn: () => {
            return fetchAllChartConfig(analysisId)
        },
        enabled: !!analysisId,
        retry: 2
    })
}

export const ChartAnalysisReactQuery = (chartConfig: any) => {
    return useQuery({
        queryKey: ['data-e-chart', chartConfig],
        queryFn: () => {
            return fetchEchart(chartConfig)
        },
        enabled: !!chartConfig,
        retry: 2
    })
}

const saveAnalysis = async (saveAnalysisDTO: any): Promise<Response> =>{
    try {
        return await axios
            .post<Response>(`${apiUrl}`, saveAnalysisDTO,{timeout:30000})
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
}

export const SaveAnalysisReactQuery = () => {
    return useMutation({
        mutationFn: (analysis) => {
            console.log("----------------------------SAVE------------------")
            console.log(analysis)
            return saveAnalysis(analysis)
        }
    })

}


