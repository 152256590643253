import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Button, ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";

interface ParamsModalProps {
    open: boolean;
    onCloseModal: any;
    brokerList: BrokerDTO[];
}
export interface BrokerDTO {
    userId: number;
    brokerId: number;
    powerStationName: string;
}
export const ModalIntensiveMonitoring = ({open, onCloseModal, brokerList}: ParamsModalProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [enablePool, setEnablePool] = useState<boolean>();

    useEffect(() => {
        setEnablePool(false)
    }, [onCloseModal]);

    const handleSend = async () => {
        if (brokerList && brokerList.length > 0) {
            try {
                for (const broker of brokerList) {
                    try {
                        await axios.post('/api/intensive-monitoring-command', {
                            userId: broker.userId,
                            brokerId: broker.brokerId,
                            enablePool: enablePool
                        });
                        enqueueSnackbar(`Monitoramento intensivo ativado para a usina ${broker.powerStationName}!`, { variant: "success" });
                    } catch (error) {
                        enqueueSnackbar(`Erro ao ativar o monitoramento intensivo para a usina ${broker.powerStationName}, tente novamente!`, { variant: "error" });
                        console.error(`Erro ao enviar comando para o broker ${broker.brokerId}:`, error);
                    }
                }
                onCloseModal();
            } catch (error) {
                enqueueSnackbar("Erro ao processar os comandos. Tente novamente!", { variant: "error" });
                console.error("Erro ao processar comandos:", error);
                onCloseModal();
            }
        }
    };

    const handleActivate = () => {
        setEnablePool(true);
    };

    const handleDeactivate = () => {
        setEnablePool(false);
    };

    return (
        <Dialog open={open} onClose={onCloseModal}>

            <DialogTitle>Monitoramento Intensivo</DialogTitle>
            <DialogContent>
                <div style={{alignItems:'center',maxHeight: '150px', overflowY: 'auto', padding: '10px', border: '1px solid #ddd'}}>
                    {brokerList.map((broker) => (
                        <div key={broker.brokerId}>{broker.powerStationName}</div>
                    ))}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                        <Button
                            onClick={handleActivate}
                            sx={{
                            backgroundColor: enablePool ? 'primary.main' : '#E0E0E0',  // Cor do botão "Ativar"
                            color: enablePool ? 'white' : '#BBBBBB',
                            '&:hover': {
                                backgroundColor: enablePool ? 'primary.dark' : '#E0E0E0',
                            },
                        }}
                        >
                            Ativar
                        </Button>
                        <Button
                            onClick={handleDeactivate}
                            sx={{
                            backgroundColor: !enablePool ? 'primary.main' : '#E0E0E0',  // Cor do botão "Desativar"
                            color: !enablePool ? 'white' : '#BBBBBB',
                            '&:hover': {
                                backgroundColor: !enablePool ? 'primary.dark' : '#E0E0E0',
                            },
                        }}>Desativar</Button>
                    </ButtonGroup>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained">
                Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}