import React, {FunctionComponent, useEffect, useState} from 'react';
import {addMinutes, format, parseISO} from 'date-fns';

import { useAppDispatch , useAppSelector} from '../../../../Config/Hooks';
import { RootState } from '../../../../Config/Store';
import {  PowerPU, getEntities ,clear} from './PowerPUForInvertersReducer'
import { SkidType } from './types/Skid';
import {isArray} from "lodash";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {AChartX, Skeleton} from "@atiautomacao/ati-ui-library";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import {useTheme} from "@mui/material";

// AXIS-X WITH 24H INTERVAL
let intervalDate:Array<string> = [];

const ChartHeatmap: FunctionComponent<{skidList: SkidType[], date: Date | null }> = ({skidList, date}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const entitiesPU:Array<PowerPU> = useAppSelector((state: RootState) => state.powerPUForInvertersChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.powerPUForInvertersChart);
    const [actualIndex, setActualIndex] = useState<number>(-1)
    const [series, setSeries] = useState<Array<any>>([]);
    const [dataPU, setDataPU] = useState<Array<PowerPU>>([]);
    const ordinalAxes = () => {
        let matrix:any = []
        for(let line = 0; line < series.length; line ++) {
            for (let column = 0; column < series[line].data.length; column++) {
                matrix.push([column,line, series[line].data[column]]);
            }
        }
        return matrix;
    }

    const callback = (args:any) => {
        const {name, data, value} = args;
        let names = series.map(el => el.name);
        return  'Horário' + ': ' + '<strong> '  +  name + '</strong>' + '<br />' + args.marker + ' ' + names[value[1]] + ' - ' + 'Gerado: ' + '<strong> '  +  data[2] + '</strong>'
    }

    useEffect(() => {
        setActualIndex(0);
        setDataPU([]);
    }, [skidList, date]);

    useEffect(() => {
        if(actualIndex >= 0 && skidList !== null && actualIndex < skidList.length){
            dispatch(clear());
            fetchDataForId(skidList[actualIndex].id);
        }
    },[actualIndex]);

    const fetchDataForId = (id: number) => {
        if (id){
            dispatch(getEntities({skids: id, date: date}))
                .then(() => {
                    dispatch(clear());
                    setActualIndex(prevState => prevState + 1);
                })
        }
    };

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        // Parseia o primeiro e último dateTime para objetos Date
        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);
        const skidName = dataPoints[0].skidName
        const equipmentId = dataPoints[0].equipmentId
        const equipmentName = dataPoints[0].equipmentName

        // Horários limite
        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                skidName: skidName
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                skidName: skidName
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }

    useEffect(() => {
        setDataPU(prevState => [...prevState, ...entitiesPU])
    }, [entitiesPU]);

    // corrigir erro ainda colocar mais dados do que o necessário quando atualiza a página
    useEffect(() => {
        if(skidList && actualIndex >= skidList.length){
            setActualIndex(-1)
        }
    }, [actualIndex, dataPU, skidList]);

    useEffect(() => {

        const newData:Array<any> = [];

        if(isArray(dataPU) && dataPU && dataPU.length > 0){
            let datePeriod: any[];
            let entities = adjustDataPoints(dataPU)
            datePeriod = Array.from(new Set(
                entities.map(
                    item => item.dateTime
                )
            ));

            intervalDate = datePeriod.map(
                item => format(new Date(item), 'HH:mm')
            );

            // SORT BY NAME EQUIPMENT DESC
            const equipmentEntitySort = [...dataPU].sort((a, b) => {
                return b.equipmentName.localeCompare(a.equipmentName);
            });


            // IDENTIFY INVERTER DISTINCT BY IDs
            const uniqueEquipmentIds = Array.from(new Set(equipmentEntitySort.map(item => item.equipmentId)));
            // INSERT THE DATA BY INVERTER ID
            uniqueEquipmentIds.forEach(element => {
                let filter = equipmentEntitySort.filter(data => data.equipmentId === element);
                let dataEquipment = adjustDataPoints(filter)
                newData.push(
                    {
                        name: filter[0].equipmentName + ' - ' + filter[0].skidName,
                        data: dataEquipment.map(element => element.powerPerUnit != null ? truncateNumber(element.powerPerUnit, 2) : '-'),
                    },
                )
            })
            const newDataSort = [...newData].sort((a,b) => {
                return b.name.localeCompare(a.name);
            })
            setSeries(newDataSort);
        }else {
            setSeries([])
        }

    }, [dataPU]);


    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'item',
            //formatter: callback,
            textStyle: {
                fontSize: 16
            },
        },
        grid: {
            show: true,
            containLabel: true,
            height: '80%',
            top: '10%',
            left: '5%',
        },
        xAxis: {
            type: 'category',
            data: intervalDate,
            name: "Horário",
            nameLocation: 'middle',
            min: '04:00',
            max: '20:00',
            nameGap: 30,
            splitArea: {
                show: true
            }
        },
        yAxis: {
            type: 'category',
            data: series.map(el => el.name),
            axisLabel: {
                fontSize: 10
            }
        },
        visualMap: {
            min: 0,
            max: 1.5,
            left: 'right',
            top: 'center',

        },
        animationDuration: 2000,
        dataZoom :[
            {
                type: 'slider',
                yAxisIndex: [0],
                filterMode: 'filter',
                zoomLock: true,
                fillerColor: '#B3B6B7',
                maxValueSpan: 8,
                width: 10,
                height: 310,
                right: '7%',
                top: '10%',
                bottom: '10%',
                brushSelect: false,
                handleSize: '30%',
                showDetail: false,
                opacity: 1,
                brushStyle: false,
                throttle: 50
            },
            {
                type: 'inside',
                id: 'insideY',
                yAxisIndex: [0],
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
                backgroundColor: 'transparent'
            }
        ] ,
        series: [
            {
                type: "heatmap",
                id: 'heatmap',
                data: ordinalAxes(),
            }
        ],
    };
    option.tooltip.formatter = callback;
    const [content, setContent] = useState<JSX.Element>()

    useEffect(() => {
        if(loading && actualIndex === -1){
            setContent(<Skeleton animation="wave" height={400} variant="rounded" width={"100%"} />)
        }else if(series.length > 0 && dataPU.length > 0){
            setContent(<AChartX
                option={option}
                width={'100%'}
                height={410}
                loading={false}
                theme={theme.palette.mode}
            />)
        }else {
            setContent(
                <DataNotFound/>
            )
        }
    }, [series, loading]);

    return (
        <>
            {content != null && content}
        </>

    )
}

export default ChartHeatmap;
