import * as React from 'react';
import {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import {FormControl, Grid, Select} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from '@mui/material/IconButton';
import {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ChartSeriesType} from "../../Shared/Types/chartSeriesType";
import {ChartAxisType} from "../../Shared/Types/chartAxisType";

interface Props {
    yAxisTag: any;
    group: string;
    yAxis:ChartAxisType;
    xAxis:ChartAxisType | null;
    series:ChartSeriesType | null;
    isMultAxis: boolean;
    onPreview: any;
    onDeleteAxis: any;
    onDisabled: boolean;
    chartConfigType: number | string;

}
export const mapPositionType: Record<string, any> = {
    'LEFT': {name:'left',ordinal: 2},
    'RIGHT': {name:'right', ordinal: 3}
}
export default function ChartConfigDataItem({yAxisTag, group, yAxis, series, isMultAxis, chartConfigType, onPreview, onDeleteAxis, onDisabled}: Props ){
    const [dataType, setDataType] = React.useState(series?.type ?? "line");
    const handleDataTypeChange = (event: SelectChangeEvent) => {
        setDataType(event.target.value);
    };

    const [dataUnity, setDataUnity] = React.useState(yAxis?.dataUnity ? yAxis.dataUnity : "avg");
    const handleDataUnityChange = (event: SelectChangeEvent) => {
        setDataUnity(event.target.value);
    };
    const getMapPositionType = (position: string | number) => {
        if (typeof position === 'string') {
            return mapPositionType[position].ordinal;
        } else {
            return position
        }
    };
    const [dataPosition, setDataPosition] = React.useState(getMapPositionType(yAxis.position));
    const handleDataPosition = (event: SelectChangeEvent) => {
        setDataPosition(event.target.value);
    };
    const unityByDataType = () => {
        if(yAxis.chartSourceConfig.teleObject?.teleObjectConfig.unity?.name){
            return yAxis.chartSourceConfig.teleObject.teleObjectConfig.unity.name
        }else if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.name){
            return yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.name
        }
        return "Sem Unidade"

    }

    const [unity, setUnity] = React.useState(unityByDataType());
    const handleUnityChange = (event: SelectChangeEvent) => {
        setUnity(event.target.value);
    };

    useEffect(() => {

        if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.dbTable){
            const updateYAxis: ChartAxisType = {
                ...yAxis,
                dataUnity: dataUnity,
                position: parseInt(dataPosition as string),
                chartSourceConfig: {
                    ...yAxis.chartSourceConfig,
                    aggregatedDataConfig : {
                        id: yAxis.chartSourceConfig.aggregatedDataConfig?.id,
                        dbColumn: yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn ? {
                            ...yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn
                        } : null,
                        equipment: yAxis.chartSourceConfig.aggregatedDataConfig?.equipment ? {
                            ...yAxis.chartSourceConfig.aggregatedDataConfig.equipment
                        } : null,
                        powerStation: yAxis.chartSourceConfig.aggregatedDataConfig?.powerStation ? {
                            ...yAxis.chartSourceConfig.aggregatedDataConfig.powerStation
                        } : null,
                        skid: yAxis.chartSourceConfig.aggregatedDataConfig?.skid ? {
                            ...yAxis.chartSourceConfig.aggregatedDataConfig.skid
                        } : null,

                    }
                }
            }
            onPreview(updateYAxis, dataType,dataPosition, yAxisTag)
        } else if(yAxis.chartSourceConfig.teleObject != null) {
            const updateYAxis: ChartAxisType = {
                ...yAxis,
                dataUnity: dataUnity,
                position: parseInt(dataPosition),
                chartSourceConfig: {
                    ...yAxis.chartSourceConfig,
                    teleObject: {
                        ...yAxis.chartSourceConfig.teleObject,
                        teleObjectConfig:{
                            name: yAxis.chartSourceConfig.teleObject.teleObjectConfig.name,
                            unity: {
                                id: yAxis.chartSourceConfig.teleObject?.teleObjectConfig?.unity?.id ?? null,
                                name: yAxis.chartSourceConfig.teleObject?.teleObjectConfig?.unity?.name ?? null
                            }
                        }
                    }
                }
            }
            onPreview(updateYAxis, dataType, dataPosition, yAxisTag)
        }
    }, [dataType, unity, dataUnity, dataPosition]);

    const multiAxisConcatenation = () => {
        let name = yAxis.name;
        if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn){
            if (yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.name) {
                name = name + " [" + yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.name+"]";
            }
            if (yAxis.chartSourceConfig.aggregatedDataConfig.skid?.name) {
                name = name + " - " + yAxis.chartSourceConfig.aggregatedDataConfig.skid.name;
            }
            if (yAxis.chartSourceConfig.aggregatedDataConfig.powerStation?.name) {
                name = name + " - " + yAxis.chartSourceConfig.aggregatedDataConfig.powerStation.name;
            }

        } else if(yAxis.chartSourceConfig.teleObject) {
            if (yAxis.chartSourceConfig.teleObject.teleObjectConfig.unity?.name) {
                name = name + " [" + yAxis.chartSourceConfig.teleObject.teleObjectConfig.unity.name+"]";
            }
            if (yAxis.chartSourceConfig.teleObject.equipment.name) {
                name = name + " - " + yAxis.chartSourceConfig.teleObject.equipment.name;
            }
            if (yAxis.chartSourceConfig.teleObject.equipment.skid?.name) {
                name = name + " - " + yAxis.chartSourceConfig.teleObject.equipment.skid.name;
            }
            if (yAxis.chartSourceConfig.teleObject.equipment.powerStation.name) {
                name = name + " - " + yAxis.chartSourceConfig.teleObject.equipment.powerStation.name;
            }
        }

        return name;
    }

    return (
        <Grid container
              direction="row"
              justifyContent="start"
              alignItems="center"
        >
            <Grid item xs={12}>
                <Typography>{isMultAxis ? multiAxisConcatenation() : yAxis.name}</Typography>
                <br/>
            </Grid>
            <br/>
            <Grid item xs={11} textAlign={"start"}>
                { chartConfigType === 1 &&
                    <FormControl
                        sx={{ minWidth: 120, flexDirection: 'row' }}
                        variant="standard"
                    >
                        <Typography sx={{display: "inline", marginRight: 1}}>Eixo-Y:</Typography>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            value={dataPosition as string}
                            onChange={handleDataPosition}
                            label="Age"
                            title='Posição do Eixo'
                            size={"small"}
                        >
                            <MenuItem value={"2"}>Esquerda</MenuItem>
                            <MenuItem value={"3"}>Direita</MenuItem>
                        </Select>
                    </FormControl>
                }
                <FormControl
                    sx={{ minWidth: 120 }}
                    variant="standard"
                >
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={dataType}
                        onChange={handleDataTypeChange}
                        label="Age"
                        title='Tipo de Gráfico'
                        size={"small"}
                    >
                        <MenuItem value={"bar"}>Barras</MenuItem>
                        <MenuItem value={"line"}>Linhas</MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    sx={{ minWidth: 120 }}
                    variant="standard"
                >
                    <Select
                        labelId="unity-label-id"
                        id="unity-label-id"
                        value={unity}
                        onChange={handleUnityChange}
                        label="Unity"
                        title='Unidade de Medida'
                        size={"small"}
                    >
                        <MenuItem value={unityByDataType()}>{unityByDataType()}</MenuItem>


                        { yAxis.chartSourceConfig.unityConverter?.map(unityConverter => {
                            return (
                                <MenuItem key={unityConverter.id} value={unityConverter.toUnity?.name}>
                                    {unityConverter.toUnity?.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                {group.includes("none") ? "" :
                    <FormControl
                        sx={{ minWidth: 120 }}
                        variant="standard"
                    >
                        <Select
                            labelId="unity-gropu-select-standard-label"
                            id="data-unity-gropu-select-standard"
                            value={dataUnity}
                            onChange={handleDataUnityChange}
                            label="Age"
                            title='Unidade de Agrupamento'
                            size={"small"}
                        >
                            {/* Bloqueios de tipos gerenciais para:
                                Temperatura (do módulo), Disponibilidade de Inversor e de Usina, fator de capacidade, fator de capacidade esperado;
                             */}
                            {!(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("expected_performance_ratio")
                                || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("performance_ratio")
                                || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("temperature")
                                || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("capacity_factor")
                                || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("expected_capacity_factor")
                                || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("availability"))
                               && <MenuItem value={"sum"}>Soma</MenuItem>
                            }
                            {!(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("expected_performance_ratio")
                                || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("performance_ratio"))
                                && <MenuItem value={"avg"}>Média</MenuItem>
                            }
                            <MenuItem value={"min"}>Mínimo</MenuItem>
                            <MenuItem value={"max"}>Máximo</MenuItem>
                            { (yAxis.chartSourceConfig.teleObject != null) &&
                                <MenuItem value={"last"}>Recente</MenuItem>
                            }
                        </Select>
                    </FormControl>
                }

            </Grid>
            <Grid item xs={1} textAlign={"end"}>
                <IconButton edge="end" aria-label="delete" onClick={() => onDeleteAxis(yAxisTag)} disabled={onDisabled}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );

}