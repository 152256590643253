import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
interface ParamsModalProps {
    open: boolean;
    onCloseModal: any;
    commandDTO: CommandDTO;
}
export interface CommandDTO {
    id: number;
    teleObjectName: string;
    userId: number;
    point: number;
    teleObjectSpecType: number;
    etsCode: number;
    brokerId: number;
    powerStationName?: string;
    skidName?: string | null;
    equipmentName?: string;
}
export const ModalForSendingTelecommand = ({open, onCloseModal, commandDTO}: ParamsModalProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setInputValue("")
    }, [onCloseModal]);

    const handleSend = async () => {
        if (commandDTO && inputValue) {
            if (!/^\d+$/.test(inputValue)) {
                enqueueSnackbar("Apenas valores numéricos são aceitos!", {variant: "error"});
                return;
            }
            try {
                const response = await axios.post('/api/send-command', {
                    userId: commandDTO.userId,
                    teleObjectId: commandDTO.id,
                    point: commandDTO.point,
                    teleObjectSpecType: commandDTO.teleObjectSpecType,
                    etsCode: commandDTO.etsCode,
                    data: Number(inputValue),
                    brokerId: commandDTO.brokerId
                });
                enqueueSnackbar("Valor enviado com sucesso!", { variant: "success" });
                console.log(response.data);
                onCloseModal()
            } catch (error) {
                enqueueSnackbar("Erro ao enviar o valor do Telecomando, tente novamente!", {variant: "error"});
                console.error("Erro ao enviar comando. Tente novamente:", error);
            }
        }
    };
    const printFullHeader = () => {
        return commandDTO?.skidName != null && commandDTO?.powerStationName != null && commandDTO?.equipmentName != null
    }
    return (
        <Dialog open={open} onClose={onCloseModal}>

            {printFullHeader() ?
                <DialogTitle>{commandDTO?.equipmentName + ' - ' + commandDTO?.skidName + ' - ' + commandDTO?.powerStationName}</DialogTitle>
            :   <DialogTitle>{commandDTO?.teleObjectName}</DialogTitle>
            }
            <DialogContent>
                {printFullHeader() && <DialogContentText>{commandDTO?.teleObjectName}</DialogContentText>}

                <TextField
                    autoFocus
                    margin="dense"
                    label="Digite o valor"
                    type="text"
                    fullWidth
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained" disabled={inputValue === ""}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}