import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {ACard, ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {DeleteGraficElementReactQuery, GraficElementReactQuery} from "./GraficElementReactQuery";
import {Link, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {AppBar, Button, Tooltip, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import {columnsDataGrid} from "./columnsDataGrid";
import {TeleObjectType} from "../../../Shared/Types/TeleObjectType";
import * as XLSX from "xlsx";
import {formatData} from "../../Dashboard/Overview/types/FormattedData";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";
import axios from "axios";
import {saveConfiguration} from "../../Reports/TeleObjectsReport/types/utils/ApiUtils";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";


export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowGraficElement {
    id?: number;
    name: string;
    actionsConfig?: Array<ActionConfig>
}

export const GraficElementSettingsPage = () => {
    const theme = useTheme();
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 100));
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const {data, isSuccess} = GraficElementReactQuery(pagedSearchParams);
    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState<Array<RowGraficElement>>([]);
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_GRAFIC_ELEMENT
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_GRAFIC_ELEMENT
    ]);
    const navigate = useNavigate();
    const mutation = DeleteGraficElementReactQuery(pagedSearchParams);


    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if (data?.data) {
                let rowsGraficElement = data.data.map((graficElement: any) => {
                    return {
                        id: graficElement.id,
                        name: graficElement.name,
                        actionsConfig: [
                            {
                                actionName: 'Editar',
                                disabled: !isAuthorizedToSave
                            },
                            {
                                actionName: 'Excluir',
                                disabled: !isAuthorizedToDelete
                            }
                        ]
                    }
                });
                setRows(rowsGraficElement);
            }
        }
    }, [data?.data]);

    const handleEditGraficElement = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_GRAFIC_ELEMENT_FORM, {state: {id: data.id}});
    }, [navigate]);

    const handleDeleteGraficElement = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        mutation.mutate({id: selectedToDelete, pagedSearchParams});
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    useEffect(() => {
        if (mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 50));
        }
    }, [mutation.isSuccess]);

    return (
        <>

            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <div style={{marginLeft: '22%'}}>
                        <Button
                            size={"small"}
                            variant="contained"
                            component={Link}
                            disabled={!isAuthorizedToSave}
                            to={SystemRoutes.SETTINGS_GRAFIC_ELEMENT_FORM}
                            endIcon={<AddCircle/>}
                        >
                            Novo
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>

            <Box marginTop={10}>
                <ACard>
                    <ADataGrid
                        columns={columnsDataGrid}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit"
                                            disabled={!isAuthorizedToSave} onClick={(value) => handleEditGraficElement(value)}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete"
                                            disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteGraficElement(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar esse elemento gráfico?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>

        </>
    );
}

export default function GraficElementHomePage(){
    const queryClient = new QueryClient();

    return(
        <QueryClientProvider client={queryClient}>
            <GraficElementSettingsPage />
        </QueryClientProvider>
    )
}
