import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {TreeView} from '@mui/x-tree-view/TreeView';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import TableChart from '@mui/icons-material/TableChart';
import { FormControl, Input, InputAdornment } from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';

import {setEquipment, setPowerPlant, setSkid,} from '../../../../Modules/Dashboard/DashboardReducer';
import {useAppDispatch, useAppSelector} from '../../../../Config/Hooks';
import {getEntities} from './SGDDashboardMenuReducer';
import {RootState} from '../../../../Config/Store';
import NavigationMenuItem from './components/NavigationMenuItem/NavigationMenuItem';
import TreeItem from './components/TreeItem/TreeItem';
import DashboardMenuSkeleton from './components/DashboardMenuSkeleton/DashboardMenuSkeleton';
import Box from '@mui/material/Box';
import {DashboardNavigationMenu} from "./types/DashboardNavigationMenu";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import useInterval from "../../../Hooks/useInterval";

const ROOT_NODE_ID = 'p0' as const;

export default function SGDDashboardMenu() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState([ROOT_NODE_ID]);
    const [selected, setSelected] = useState("");
    const [search, setSearch] = useState('');
    const hasFetchedEntities = useRef(false);

    const { entities, isLoading } = useAppSelector(
        (state: RootState) => state.dashboardNavigationMenu
    );

    useEffect(() => {
        if (!hasFetchedEntities.current) {
            dispatch(getEntities());
            hasFetchedEntities.current = true;
        }
    }, []);

    useInterval(() => {
        dispatch(getEntities());
    }, 60000); //1 minute

    const handleToggle = (event: any, nodeIds: any) => {
        setExpanded(nodeIds);
    };

    const compareByName = (a: string, b: string): number => {
        const aParts = a.match(/(\D+)(\d*)/);
        const bParts = b.match(/(\D+)(\d*)/);

        if (aParts && bParts) {
            const nameCompare = aParts[1].localeCompare(bParts[1]);
            if (nameCompare !== 0) return nameCompare;
            const aNumber = parseInt(aParts[2] || '0', 10);
            const bNumber = parseInt(bParts[2] || '0', 10);
            return aNumber - bNumber;
        }

        return a.localeCompare(b);
    };


    const sortItems = (items: any[]): any[] => {
        return items
            .map((item: any) => ({
                ...item,
                skidList: [...item.skidList]
                    .map((skid: any) => ({
                        ...skid,
                        equipmentList: [...skid.equipmentList].sort((a: any, b: any) => compareByName(a.name, b.name)),
                    }))
                    .sort((a: any, b: any) => compareByName(a.name, b.name)),
                equipmentList: [...item.equipmentList].sort((a: any, b: any) => compareByName(a.name, b.name)),
            }))
            .sort((a: any, b: any) => compareByName(a.name, b.name));
    };


    const handleSkidOrEquipmentSelection = (entity: DashboardNavigationMenu, selectedId: number, selectedName: string) => {
        if (entity.equipmentList) {
            const equipment = entity.equipmentList.find(
                (equip) => equip.id === selectedId && selectedName === equip.name
            );
            if (equipment) {
                dispatch(setPowerPlant(entity));
                dispatch(setSkid(null));
                dispatch(setEquipment(equipment));
                navigate(`/dashboard/power-plant/equipments/details`);
                return;
            }
        }
        for (const skid of entity.skidList) {
            if (skid.id === selectedId && selectedName === skid.name) {
                dispatch(setPowerPlant(entity));
                dispatch(setSkid(skid));
                navigate(`/dashboard/power-plant/skid/overview`);
                return;
            }
            if (skid.equipmentList) {
                const equipment = skid.equipmentList.find(
                    (equip) => equip.id === selectedId && selectedName === equip.name
                );

                if (equipment) {
                    dispatch(setPowerPlant(entity));
                    dispatch(setSkid(skid));
                    dispatch(setEquipment(equipment));
                    navigate(`/dashboard/power-plant/skid/equipments/details`);
                    return;
                }
            }
        }
    }

    const handleSelect = (event: any, nodeIds: any) => {
        setSelected(nodeIds);
        const selectedId = parseInt(nodeIds.substring(1));
        const selectedName: string = event.target.innerHTML;
        if(selectedId === 0) navigate("/dashboard/overview");
        const findEntityById = (id: number) =>
            entities.find((entity) => entity.id === id && selectedName === entity.name);

        const entity = findEntityById(selectedId);
        if (entity) {
            dispatch(setPowerPlant(entity));
            navigate(`/dashboard/power-plant/overview`);
            return;
        }

        for (const entity of entities) {
            if (entity.skidList) {
                handleSkidOrEquipmentSelection(entity, selectedId, selectedName);
            }
        }
    };

    const generateItems = (search?: string | null | undefined): React.ReactNode[] => {
        if (!search) {
            return sortItems(entities).map((item) => (
                <NavigationMenuItem key={item.id} item={item} />
            ));
        }
        const filters = [search.toLowerCase()];

        return entities
            .filter((powerPlant: DashboardNavigationMenu) => {
                for (let i = 0; filters && i < filters.length; i++) {
                    if (powerPlant.name.toLowerCase().includes(filters[i].toLowerCase())) return true;
                    for (const skid of powerPlant.skidList) {
                        if (skid.name.toLowerCase().includes(filters[i].toLowerCase())) return true;
                        for (const equipment of skid.equipmentList) {
                            if (equipment.name.toLowerCase().includes(filters[i].toLowerCase())) return true;
                        }
                    }
                }
                return false;
            })
            .map((powerPlant) => (
                <NavigationMenuItem key={powerPlant.id} item={powerPlant} />
            ));
    };
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // const menuToogle = () => {
    //     dispatch(toggleSubMenu());
    // }
    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const drawerWidth = 440;
    // const AppBar = styled(MuiAppBar, {
    //     shouldForwardProp: (prop) => prop !== 'open',
    // })<AppBarProps>(({ theme, open }) => ({
    //     transition: theme.transitions.create(['margin', 'width'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     ...(open && {
    //         width: `calc(100% - ${drawerWidth}px)`,
    //         marginLeft: `${drawerWidth}px`,
    //         transition: theme.transitions.create(['margin', 'width'], {
    //             easing: theme.transitions.easing.easeOut,
    //             duration: theme.transitions.duration.enteringScreen,
    //         }),
    //     }),
    // }));

    return (
        <React.Fragment>
            <FormControl
                fullWidth
                variant="standard"
                style={{ marginTop: 8, marginLeft: 11 }}
            >
                <Input
                    id="search-power-plant"
                    placeholder={'Filtrar'}
                    endAdornment={
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon="magnifying-glass" />
                        </InputAdornment>
                    }
                    onChange={(event) => setSearch(event.target.value)}
                />
            </FormControl>
            {isLoading ? (
                <DashboardMenuSkeleton />
            ) : (
                <>
                    <Box
                        style={{
                            height: window.innerHeight - 150,
                            overflowY: 'auto',
                            paddingTop: 20,
                        }}
                    >
                        <TreeView
                            aria-label="gmail"
                            expanded={expanded}
                            selected={selected}
                            defaultCollapseIcon={<RemoveOutlinedIcon />}
                            defaultExpandIcon={<AddOutlinedIcon />}
                            defaultEndIcon={<div style={{ width: 24 }} />}
                            onNodeToggle={handleToggle}
                            onNodeSelect={handleSelect}
                        >
                            <TreeItem
                                key={987}
                                nodeId={ROOT_NODE_ID}
                                defaultChecked={true}
                                labelText={'Visão Geral '}
                                labelIcon={TableChart}
                            >
                                {generateItems(search)}
                            </TreeItem>
                        </TreeView>
                    </Box>
                </>
            )}
        </React.Fragment>
    );
}