import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {Storage} from "../Utils/StorageUtils";
import {GATEWAY_URL} from "./Constants";

const TIMEOUT = 30 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = GATEWAY_URL;

export function setupInterceptorsTo(onUnauthenticated: () => void): void {

    const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
        const token = Storage.local.get('api-token-key') || Storage.session.get('api-token-key');
        const email = Storage.local.get('user-mail') || Storage.session.get('user-mail');

        // if (config && config.headers && token && config.url !== "api/authenticate") {
        // console.log("config.url", config.baseURL);
        if (config && config.headers && token && email) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['Email'] = email;
        }
        return config;
    }

    const onRequestError = (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
    }

    const onResponse = (response: AxiosResponse): AxiosResponse => {
        return response;
    }

    const onResponseError = (error: AxiosError): Promise<AxiosError> => {

        const status = error.status || (error.response ? error.response.status : 0);
        if (status === 403 || status === 401) {
            window.history.pushState(undefined,"undefined unused", "/");
            onUnauthenticated();
        }

        return Promise.reject(error);
    }

    axios.interceptors.request.use(onRequest, onRequestError);
    axios.interceptors.response.use(onResponse, onResponseError);
}
