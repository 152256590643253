import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {
    ACard,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    AChartX
} from "@atiautomacao/ati-ui-library";
import {GeneratedEnergy} from "../DashboardPowerPlantCharts";
import {RootState} from "../../../../Config/Store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileCsv} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {downloadFile} from "../../../../Utils/DownloadUtils";
import {
    getGeneratedEnergyDay,
    getGeneratedEnergyMonth,
    getGeneratedEnergyYear
} from "./DashboardPowerPlantChartsReducer";
import {Box, styled, useTheme} from "@mui/material";
import {format} from "date-fns";
import {ptBR} from "date-fns/locale";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {isArray} from "lodash";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {hasPermission} from "../../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../../Config/Constants";

const PeriodSelectorContainer = styled(Box)`
    display: flex;
    flex-flow: row;
    justify-content: end;
    background-color: white;
`;

const GenerationChart: FunctionComponent<{ powerPlant: any;}> = ({powerPlant}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.VIEW_ENERGY_GENERATION_EXPORT, AUTHORITIES.CSV_ENERGY_GENERATION_EXPORT
    ]);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const {generatedEnergy, loading} = useAppSelector((state: RootState) => state.dashboardPowerPlantCharts);

    const generateSeries = useCallback((): ({ data: any; name: string; tooltip: { valueFormatter: (value: number) => string }; type: string } | { unity: string; data: any; name: string; type: string; yAxisIndex: number })[] => {
        const isYearOrGeneral = period.groupBy === 'year' || period.groupBy === 'general';
        const irradianceUnit = isYearOrGeneral ? 'kWh/m²' : 'Wh/m²';

        const genEnergy = {
            name: 'Energia Gerada',
            type: 'bar',
            itemStyle: {
                color: '#5470C6' // Azul
            },
            tooltip: {
                valueFormatter: (value: number) => `${value} MWh`
            },
            data: generatedEnergy.map((generation: GeneratedEnergy) => generation.generatedEnergy ? truncateNumber(generation.generatedEnergy, 2) : "-")
        };

        const expectedEnergy = {
            name: 'Energia Esperada',
            type: 'bar',
            itemStyle: {
                color: '#91CC75' // Verde
            },
            tooltip: {
                valueFormatter: (value: number) => `${value} MWh`
            },
            data: generatedEnergy.map((generation: GeneratedEnergy) => generation.expectedInjectedEnergy ? truncateNumber(generation.expectedInjectedEnergy, 2) : "-")
        };

        const irradiance = {
            name: 'Irradiação',
            type: 'line',
            itemStyle: {
                color: '#F4CA6C' // Amarelo
            },
            tooltip: {
                valueFormatter: (value: number) => `${value} ${irradianceUnit}`
            },
            yAxisIndex: 1,
            data: generatedEnergy.map((generation: GeneratedEnergy) => generation.irradiance ? truncateNumber(generation.irradiance, 2) : "-")
        };

        const expectedIrradiance = {
            name: 'Irradiação Esperada',
            type: 'line',
            itemStyle: {
                color: '#EE6666' // Vermelho
            },
            tooltip: {
                valueFormatter: (value: number) => `${value} ${irradianceUnit}`
            },
            yAxisIndex: 1,
            data: generatedEnergy.map((generation: GeneratedEnergy) => generation.expectedIrradiance ? truncateNumber(generation.expectedIrradiance, 2) : "-")
        };

        return [genEnergy, expectedEnergy, irradiance, expectedIrradiance];
    }, [generatedEnergy]);

    const getMaxYAxisValue = useCallback((): number => {
        const values = [
            ...generatedEnergy.map((generation: GeneratedEnergy) => generation.generatedEnergy ? truncateNumber(generation.generatedEnergy) : generation.generatedEnergy),
            ...generatedEnergy.map((generation: GeneratedEnergy) => generation.expectedInjectedEnergy ? truncateNumber(generation.expectedInjectedEnergy) : generation.expectedInjectedEnergy),
        ]
        return values.length > 0 ? Math.max(...values) : 0;
    }, [generatedEnergy]);

    const handleCsvDownload = () => {
        let apiUrl = '';
        switch (period.groupBy) {
            case "year":
                apiUrl = `api/generation-energy/power-station/${powerPlant.id}/csv/month`
                break;
            case "general":
                apiUrl = `api/generation-energy/power-station/${powerPlant.id}/csv/year`
                break;
            default:
                apiUrl = `api/generation-energy/power-station/${powerPlant.id}/csv/day`
        }
        const params = {
            startDateTime: period.fromDateTime?.toJSON().split('.')[0],
            endDateTime: period.toDateTime?.toJSON().split('.')[0],
        };

        downloadFile(apiUrl, params, 'energia-gerada.csv');
    }

    const [period, setPeriod] = useState<Period>(() => {
        const toDateTime = new Date();
        const fromDateTime = new Date();

        return {
            groupBy : "week" ,
            fromDateTime,
            toDateTime
        }
    });


    const handleDateTimeFormat = ():Array<any> => {
        try {
            return generatedEnergy.map((generation) => {
                if (!generation.dateTime) {
                    return "Data Ausente";
                }

                if (period.groupBy === "year" && RegExp(/^\d{4}-\d{2}$/).exec(generation.dateTime)) {
                    const [year, month] = generation.dateTime.split('-');
                    const dataCompleta = new Date(parseInt(year), parseInt(month) - 1, 1);
                    return format(dataCompleta, 'MMM', { locale: ptBR });
                } else if (period.groupBy === "week" || period.groupBy === "month" || period.groupBy === "none") {
                    return format(new Date(generation.dateTime + ' '), 'dd/MM', { locale: ptBR });
                } else if(period.groupBy === "general"){
                    return generation.dateTime;
                }
                else {
                    return "Formato de Data Inválido";
                }
            });
        } catch (error) {
            console.error("Erro ao formatar datas:", error);
            return [];
        }
    }

    const fetchGeneratedEnergy = () => {
        if (powerPlant && checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            switch (period.groupBy) {
                case "year":
                    dispatch(getGeneratedEnergyMonth({
                        id: powerPlant.id,
                        startDate: period.fromDateTime,
                        endDate: period.toDateTime
                    }));
                    break;
                case "general":
                    dispatch(getGeneratedEnergyYear({
                        id: powerPlant.id,
                        startDate: period.fromDateTime,
                        endDate: period.toDateTime
                    }));
                    break;
                default:
                    dispatch(getGeneratedEnergyDay({
                        id: powerPlant.id,
                        startDate: period.fromDateTime,
                        endDate: period.toDateTime
                    }));
            }
        }
    };

    useEffect(() => {
        fetchGeneratedEnergy();
    }, [period, powerPlant, dispatch]);

    useInterval(() => {
        fetchGeneratedEnergy();
    }, 60000) // 1 minute



    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                magicType: { show: false, type: ['line', 'bar'] },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        xAxis: {
            type: 'category',
            data: handleDateTimeFormat(),
            nameLocation: 'middle',
            nameGap: 70,
        },
        yAxis: [
            {
                name: 'MWh',
                max: getMaxYAxisValue() + getMaxYAxisValue() * 0.15,
                min: 0,
                interval: truncateNumber(getMaxYAxisValue() / 5),
                nameGap: 70,
                namePosition: 'middle'
            },
            {
                name: 'Wh/m²',
                max: truncateNumber(Math.max(
                    ...generatedEnergy.map(value => value.irradiance),
                    ...generatedEnergy.map(value => value.expectedIrradiance)
                )),
                min: 0,
                interval: truncateNumber(Math.max(
                    ...generatedEnergy.map(value => value.irradiance),
                    ...generatedEnergy.map(value => value.expectedIrradiance)
                ) / 5),
                nameGap: 70,
                namePosition: 'middle'
            }
        ],
        legend: {
            show: true,
            type: 'scroll',
            orient: 'horizontal', // Altera para horizontal
            bottom: 1, // Coloca a legenda na parte inferior
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: generateSeries().map(el => el.name)
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 40
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: generateSeries(),
    }

    let content;
    if (loading) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else if (isArray(generatedEnergy) && generatedEnergy.length > 0) {
        content = (
            <AChartX
                option={option}
                width={'100%'}
                height={289}
                loading={false}
                theme={theme.palette.mode}
            />
        );
    } else {
        content = <DataNotFound boxStyle={{ height: 289, width: '100%' }} />;
    }

    return (
        <ACard
            key={`chart-power-energy-${openSubMenu}`}
            styleProps={{
                cardStyle: { height: 445 },
                contentStyle: { padding: 0 }
            }}
            title="Energia Gerada"
            headerControls={
                <PeriodSelector
                    styleProps={{ base: { flexDirection: "row", display: "flex", alignItems: "center" } }}
                    mode='hybrid'
                    disableHourSelector={true}
                    inputFormat={'dd/MM/yyyy'}
                    period={period}
                    periodInterval={{
                        week: { startTime: "09:00:00", endTime: "23:50:00" },
                        month: { startTime: "09:30:00" },
                        year: { startInterval: 10, endInterval: 0 },
                        general: { startInterval: 10, endInterval: 0 }
                    }}
                    groupByOptions={['week', 'month', 'year', 'general']}
                    onChange={setPeriod}
                    disabled={loading}
                />
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20} />}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20} />}
            //             text="Expand"
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20} />}
            //             text="Print"
            //         />
            //     </>
            // }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20} />}>
                    <IconButton disabled={!isAuthorizedToSearchByCsv}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20} onClick={handleCsvDownload} />
                    </IconButton>
                </APopoverIcon>
            }
        >
            {content}
        </ACard>
    );
}

export default GenerationChart;