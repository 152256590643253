import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightThemeConfig, darkThemeConfig } from './Shared/Theme';
import App from './App';

const RootComponent = () => {
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');

    const lightTheme = createTheme(lightThemeConfig);
    const darkTheme = createTheme(darkThemeConfig);

    const handleThemeMode = () => {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    console.log(darkTheme)
    return (
        <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
            <App handleThemeMode={handleThemeMode} />
        </ThemeProvider>
    );
};

export default RootComponent;