import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, Paper, Stack} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import {AnalysisEntity, AnalysisHomeReactQuery, DeleteAnalysisByIdReactQuery} from "./AnalysisHomeReactQuery";
import DashboardMenuSkeleton from "../components/DashboardMenuSkeleton/DashboardMenuSkeleton";
import {QueryClient, QueryClientProvider} from 'react-query';
import {useLocation, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../../../Utils/RouteUtils";
import {isArray} from 'lodash';
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const AnalysisComponent = () => {

    const navigate = useNavigate();
    const {state}= useLocation();

    const [analysisList, setAnalysisList] = useState<Array<AnalysisEntity>>([]);
    const {data, isSuccess, isLoading, isFetching, refetch} = AnalysisHomeReactQuery();
    const mutation = DeleteAnalysisByIdReactQuery();

    const setSelectedAnalysis = (item:AnalysisEntity) => {
        let chartConfigList: { id: number, name: string | undefined }[] = [];
        item.chartConfigList.forEach(element => {
            if (element?.id) {
                chartConfigList.push({
                    id: element.id,
                    name: element.name // Armazena tanto o id quanto o nome
                });
            }
        })
        navigate(`${SystemRoutes.ANALYSIS}/${item.id}/${chartConfigList.map(config => config.id)}`, {
            state: { chartList: chartConfigList }
        });
    }

    const handleListItemClick = (item:AnalysisEntity) => {
        if(item?.id != undefined) {
            setSelectedAnalysis(item)
            const updatedAnalysisList = analysisList.map((analysis) => {
                if (analysis.id === item.id && !analysis.selected) {
                    return { ...analysis, selected: true };
                } else {
                    return { ...analysis, selected: false };
                }
            });
            if(analysisList.length > 0) {
                setAnalysisList(updatedAnalysisList);
            }
        }
    }

    useEffect(() => {
        if(isSuccess){
            if(data?.data.length > 0) {
                const list = data?.data
                const updatedAnalysisList = list.map((analysis) => {
                    if(analysis.id === list[0].id){
                        return { ...analysis, selected: true };
                    }
                    return { ...analysis, selected: false };
                });
                setSelectedAnalysis(list[0])
                setAnalysisList(updatedAnalysisList)
            }
        }
    }, [data?.data]);

    useEffect(() => {

        if(state?.reload){
            mutation.mutate(state?.reload)
        }
    }, [state]);


    useEffect(() => {
        if (mutation.isSuccess) {
            refetch().then(r => r.data);
        }
    }, [mutation.isSuccess]);


    // @ts-ignore
    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                <nav aria-label="main mailbox folders">
                    <Stack spacing={2}>
                        <InputLabel id="analysis-label">Análises</InputLabel>
                        <Divider/>
                        {isFetching || isLoading  || !isArray(analysisList) ? (
                            <DashboardMenuSkeleton/>
                        ) : (
                            <List
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 800,
                                    '& ul': {padding: 0},
                                }}
                                subheader={<li/>}
                            >
                                {
                                    isArray(analysisList) && analysisList.length > 0 && analysisList.map((analysis) => (
                                        <>
                                            <ListItem disablePadding style={{borderRadius: 10}} key={analysis.id}>
                                                <ListItemButton
                                                    key={analysis.id}
                                                    selected={analysis.selected}
                                                    onClick={() => handleListItemClick(analysis)}
                                                    style={{borderRadius: 10}}
                                                >
                                                    <ListItemIcon>
                                                        <FontAwesomeIcon icon={faChartSimple} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={analysis.name}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </>

                                    ))
                                }
                            </List>
                        )}
                    </Stack>
                </nav>
            </Box>
    );
}

export default function SGDAnalysisMenu() {
    const queryClient = new QueryClient();
    return(
        <QueryClientProvider client={queryClient}>
            <AnalysisComponent/>
        </QueryClientProvider>
    )
}