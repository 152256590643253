import {AppBar, Button, Tooltip, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {Link, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import React, {useCallback, useEffect, useState} from "react";
import {columnsDataGrid} from "../GraficElement/columnsDataGrid";
import {ACard, ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {DeleteNotificationGroupReactQuery, NotificationReactQuery} from "./reactQuery/NotificationGroupReactQuery";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import Box from "@mui/material/Box";
import {QueryClient, QueryClientProvider} from "react-query";

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowNotificationGroup {
    id?: number;
    name: string;
    actionsConfig?: Array<ActionConfig>
}
export const NotificationGroupPage = () => {
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 100));
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState<Array<RowNotificationGroup>>([]);
    const {data, isSuccess} = NotificationReactQuery(pagedSearchParams);

    const theme = useTheme();

    const navigate = useNavigate();
    const mutation = DeleteNotificationGroupReactQuery(pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.data?.totalOfRecords);
            if (data?.data) {
                let rowsNotificationGroup = data?.data?.data?.map((notificationGroup: any) => {
                    return {
                        id: notificationGroup.id,
                        name: notificationGroup.name,
                        actionsConfig: [
                            {
                                actionName: "Editar",
                                disabled: false
                            },
                            {
                                actionName: "Excluir",
                                disabled: false
                            }
                        ]
                    }
                });
                setRows(rowsNotificationGroup);
            }
        }
    }, [data?.data]);

    const handleEditNofiticationGroup = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_NOTIFICATION_GROUP_FORM, {state: {id: data.id}});
    }, [navigate]);

    const handleDeleteNotificationGroup = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        mutation.mutate({id: selectedToDelete, pagedSearchParams});
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    useEffect(() => {
        if (mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 50));
        }
    }, [mutation.isSuccess]);

    return (
        <>
            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <div style={{marginLeft: '22%'}}>
                        <Button
                            size={"small"}
                            variant="contained"
                            component={Link}
                            to={SystemRoutes.SETTINGS_NOTIFICATION_GROUP_FORM}
                            endIcon={<AddCircle/>}
                        >
                            Novo
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>


            <Box marginTop={10}>
                <ACard>
                    <ADataGrid
                        columns={columnsDataGrid}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit"
                                            onClick={(value) => handleEditNofiticationGroup(value)}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete"
                                            onClick={(value) => handleDeleteNotificationGroup(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar essa notificação de grupo?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>

        </>
    )

}

export default function NotificationGroupHomePage () {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <NotificationGroupPage/>
        </QueryClientProvider>
    )
}