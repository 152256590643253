import React, {ReactElement, useEffect, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {GenerationEntity, getEntities} from "./GenerationYieldChartReducer";
import {Button, useTheme} from "@mui/material";
import {format} from "date-fns";
import {truncateNumber} from "../../../Utils/NumberUtil";
import {
    AChartX,
    XAxis,
    YAxis,
    Period,
    Skeleton,
    PeriodSelectorGroupProps
} from "@atiautomacao/ati-ui-library"
import DataNotFound from "../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../Shared/Hooks/useInterval";
import {ColorInformationGenerationYieldChart, NewHtmlTooltip} from "../../../Utils/ColorInformation";

export const COLORS = {maxLimit: "#91cc75", midLimit: "#eee766", minLimit: "#ee6565"}

interface GenerationYieldProps {
    period?: Period;
    periodIntervalGroup? : PeriodSelectorGroupProps;
}
const GenerationYield = (props: GenerationYieldProps): ReactElement => {
    const theme = useTheme();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const entities: Array<GenerationEntity> = useAppSelector((state: RootState) => state.generationYieldChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationYieldChart);
    const dispatch = useAppDispatch();

    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const periodInterval = {
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
        general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
    };

    const [period, setPeriod] = useState<Period>(props.period ? props.period : initialPeriod);
    const [data, setData] = useState<Array<number>>(Array<number>);
    const [dateTime, setDateTime] = useState<any>();
    const [yAxis, setYAxis] = useState<YAxis>();
    const [xAxis, setXAxis] = useState<XAxis>();

    useEffect(() => {
        if(props.period){
            setPeriod(props.period);
        }
    }, [props.period]);

    function ordination(newData: Array<GenerationEntity>){
        if(newData.length > 0){
            newData.sort(function compare (a, b){
                if(a.productivityGoal > b.productivityGoal){ return 1; }
                if(a.productivityGoal < b.productivityGoal){ return -1; }
                else{ return  0; }
            })
        }
        return newData;
    }

    const customTooltip = (args:any) => {
        let entitiesGeneration: GenerationEntity[] = [];

        if (!Array.isArray(entities)){
            return undefined;
        }else if(entities != null && entities.length > 0){
            let aux = [...entities]
            entitiesGeneration = ordination(aux)
            let result: any = `${args.marker}Atual: ${entitiesGeneration[args.dataIndex].yieldField ? truncateNumber(entitiesGeneration[args.dataIndex].yieldField) : '-'} kWh/kWp`;
            result+= '<br/>' + `${args.marker}Esperada: ${entitiesGeneration[args.dataIndex].expectedYield ? truncateNumber(entitiesGeneration[args.dataIndex].expectedYield) : '-'} kWh/kWp`;
            return result;
        }
    }

    const callback = (args:any) => {
        let date= dateTime.date

        let result : any =   args[0].name + '<br />  ' + (date[args[0].dataIndex]? date[args[0].dataIndex] + '<br /> ' : '')
        for(const element of args) {
            if (customTooltip) {
                result += customTooltip(element)
            }
            result += '<br/>' + element.marker + element.seriesName + ': ' + element.data + ' '+ '%' + '<br />'
        }
        return result;
    }

    function customColor(value: any) {
        if(value){
            if (value >= 90) {
                return COLORS.maxLimit;
            }
            else if (value < 90 && value >= 80 ) {
                return COLORS.midLimit;
            }
            else {
                return COLORS.minLimit;
            }
        }
    }

    const formatCaption = (fromDateTime: Date, toDateTime: Date) => {

        let caption = format(fromDateTime, 'dd/MM') + ' à ' + format(toDateTime, 'dd/MM');

        if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month") {
            caption = format(fromDateTime, 'dd/MM') + ' à ' + format(toDateTime, 'dd/MM')
        } else if (period.groupBy.valueOf() === 'year') {
            caption = format(fromDateTime, 'MMM') + ' à ' + format(toDateTime, 'MMM')
        } else if(period.groupBy.valueOf() === 'general') {
            caption = format(fromDateTime, 'yyyy') + ' à ' + format(toDateTime, 'yyyy')
        }

        return caption
    }

    const dateByPeriod = () => {
        if(Array.isArray(entities) && entities != null) {

            let fromDateTime = new Date();
            let toDateTime = new Date();
            let captionData: { date: Array<string> } = {date: []};

            if (period.fromDateTime) {
                fromDateTime = period.fromDateTime
            }
            if (period.toDateTime) {
                toDateTime = period.toDateTime
            }

            entities.forEach(() => {
                captionData.date.push(formatCaption(fromDateTime, toDateTime))
            })

            setDateTime(captionData);
        }
    }

    function findGeneratedYieldComparisonDataByPeriod(){
        let aux = [...entities]
        let receiveData =  ordination(aux);

        setXAxis({
            min: 0,
            max: 100,
            interval: 25,
            nameLocation: 'middle',
            nameGap: 0,
        });
        setYAxis(
            {
                type: 'category',
                data: Array.isArray(receiveData) ? receiveData.map(item => item.name) : [],
                max: receiveData.length == 1 ? 1 : undefined,
                min: receiveData.length == 1 ? -1 : undefined,
                axisLabel: {
                    align: 'right',
                    show: true
                },
                axisTick: { show: false },
                axisLine: { show: false },
            });
        setData( Array.isArray(receiveData) ? receiveData.map(item => item.productivityGoal ? truncateNumber(item.productivityGoal) : 0) : []);
    }

    useEffect(() => {
        // if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(getEntities(period));
        // }
    },[period]);

    useInterval(() => {
        dispatch(getEntities(period));
    }, 60000) // 1 minute

    useEffect(() => {
        if(Array.isArray(entities) && entities !== null) {
            findGeneratedYieldComparisonDataByPeriod();
            dateByPeriod();
        }else {
            setData([])
            setXAxis({})
            setDateTime(null)
        }
    },[entities]);

    const entitiesLength = () =>{
        if(entities != null ) {
            return entities.length > 0 ? entities.length : 1;
        }else{
            return 1;
        }
    }

// @ts-ignore
    const option: AChartXProps['option'] = {
        color: [COLORS.maxLimit, 'rgb(234, 162, 40)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: {
            type: 'scroll',
            orient: 'horizontal', // Mudar para orientação horizontal
            right: '275',
            bottom: 10, // Posicionar a legenda abaixo do gráfico// Alinhar a legenda à esquerda
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ["Meta"]
        },
        grid: {
            containLabel: true,
            left: '3%',
            right: '4%',
            bottom: '12%'
        },

        dataZoom :[
            {
                type: 'slider',
                yAxisIndex: entitiesLength() <= 5 ? 1 : 0 ,
                filterMode: 'filter',
                zoomLock: true,
                width: 10,
                right: 10,
                top: '10%',
                bottom: '10%',
                start: (100-(100*5)/entitiesLength()) , //entitiesLength
                brushSelect: false,
                handleSize: '60%',
                showDetail: false,
                opacity: 1,
                brushStyle: false,
            },
            {
                type: 'inside',
                id: 'insideY',
                yAxisIndex: 0,
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
                backgroundColor: 'transparent'
            }
        ] ,
        xAxis: xAxis,
        yAxis: yAxis,
        series: [
            {
                name: 'Meta',
                data: data,
                type: 'bar',
                unity: '%',
                itemStyle:{
                    color: function (param:any) { return customColor(param.value) }
                }
            }]
    };
    option.tooltip.formatter = callback;



    let content;

    if (loading) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else if (entities && entities.length > 0 && yAxis && xAxis) {

        content = <AChartX key={`card-overview-energy-yield-${openSubMenu}`} option={option} height={289} width="100%" loading={false} theme={theme.palette.mode}/>;
    } else {
        content = <DataNotFound boxStyle={{ height: 289, width: '100%' }} />;
    }

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: -43, left: 118 }}>
                <NewHtmlTooltip
                    title={<ColorInformationGenerationYieldChart />}
                >
                    <Button>HTML</Button>
                </NewHtmlTooltip>
            </div>
            {content}
        </div>
    );
}

export default GenerationYield;