import axios from "axios";

export const findAllCommands = async (equipmentId:number) => {
    const apiUrl = 'api/equipment/find-all-tele-commands'
    try {
        return await axios.get(`${apiUrl}/${equipmentId}`);
    } catch (error) {
        return error;
    }
}

export const findAllBrokers = async () => {
    const apiUrl = 'api/broker/find-all'
    try {
        return await axios.get(`${apiUrl}`);
    } catch (error) {
        return error;
    }
};