import React, {FunctionComponent, useState} from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {
    ACard,
    ACardSplitFooterActions, APopoverIcon,
    FloatMenuItem,
    Period,
    PeriodSelector,
    PeriodSelectorGroupBy
} from "@atiautomacao/ati-ui-library";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faExpand,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faGear,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import SkidComparisonChart from "../SkidComparisonChart/SkidComparisonChart";
import GenerationRateChart from "../GenerationRateChart/GenerationRateChart";
import {useAppSelector} from "../../../../Config/Hooks";

const GenerationRateAndComparisonSkids: FunctionComponent<{ powerPlant: any; }> = ({powerPlant}) => {

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const [prValue, setPrValue] = React.useState(0);

    const initialSkidComparisonPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const initialGenerationRatePeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const SkidComparisonGroupsInterval = {
        day: {date: new Date(), startTime: "00:00:00", endTime: "23:59:59"},
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
    };
    const skidComparisonGroups : PeriodSelectorGroupBy[] = ["day", "week", "month", "year"];

    const GenerationRateGroupsInterval={
        day: {date: new Date(), startTime: "00:00:00", endTime: "23:59:59"},
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
    };

    const generationRateGroups: PeriodSelectorGroupBy[] = ["day", "week", "month", "year"];


    const [period, setPeriod] = useState<Period>(prValue === 0 ? initialSkidComparisonPeriod : initialGenerationRatePeriod);


    const handleTabChange = (period: Period) => {
        if(prValue === 0){
            period.groupBy = initialSkidComparisonPeriod.groupBy;
        }else {
            period.groupBy = initialGenerationRatePeriod.groupBy;
        }
        setPeriod(period);
    }

    const handleChangeChart = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setPrValue(parseInt(value));
    };


    return (
        <ACard
            key={`chart-power-energy-comparison-${openSubMenu}`}
            title="Comparação de Skids"
            headerControlsPosition={"header"}
            headerControls={
                <>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        disableHourSelector={true}
                        inputFormat={'dd/MM/yyyy'}
                        hideGroup={false}
                        periodInterval={prValue === 0 ? SkidComparisonGroupsInterval : GenerationRateGroupsInterval}
                        period={period}
                        onChange={handleTabChange}
                        groupByOptions={prValue === 0 ? skidComparisonGroups : generationRateGroups}
                    />
                </>
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //             disable={true}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //             disable={true}
            //         />
            //     </>
            // }
            styleProps={{
                contentStyle: {paddingTop: 50, height: 405}
            }}
            footerActions={
                <ACardSplitFooterActions
                    leftActions={
                        <FormControl>
                            <RadioGroup
                                style={{marginLeft: 10}}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="controlled-radio-buttons-group"
                                value={prValue}
                                onChange={handleChangeChart}
                            >
                                <FormControlLabel value={0} control={<Radio size='small'/>} label="Energia Gerada"  sx={{ margin: 'auto', padding: '0px' }} />
                                <FormControlLabel value={1} control={<Radio size='small'/>} label="Taxa de Geração" sx={{ margin: 'auto', padding: '0px' }} />
                            </RadioGroup>
                        </FormControl>
                    }
                    leftGridWidth={9}
                    rightActions={
                        <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                            <IconButton disabled={true}>
                                <FontAwesomeIcon icon={faFilePdf} fontSize={20}/>
                            </IconButton>
                            <IconButton disabled={true}>
                                <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                            </IconButton>
                            <IconButton disabled={true}>
                                <FontAwesomeIcon icon={faFileExcel} fontSize={20}/>
                            </IconButton>
                        </APopoverIcon>
                    }
                    rightGridWidth={3}
                />
            }
        >
            { prValue === 0 ?
                <SkidComparisonChart period={period} powerPlant = {powerPlant}/>
                :
                <GenerationRateChart period={period} powerPlant = {powerPlant}/>
            }
        </ACard>
    )

}
export default GenerationRateAndComparisonSkids;