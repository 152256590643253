import * as React from 'react';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileLines} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {SystemRoutes} from "../../../../Utils/RouteUtils";
import {useAppSelector} from "../../../../Config/Hooks";
import {hasPermission} from "../../../Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../../Config/Constants";

const style = {
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
};

export default function SGDRecordsMenu() {
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToAlarms = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_ALARM
    ]);
    const isAuthorizedToPerformanceSummary = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_PERFORMANCE_SUMMARY
    ]);
    const isAuthorizedToPowerPlant = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_POWER_PLANT
    ]);
    const isAuthorizedToEquipment = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_EQUIPMENT
    ]);
    const isAuthorizedToEquipmentClass = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_EQUIPMENT_CLASS
    ]);
    const isAuthorizedToManagementReport = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_MANAGEMENT_REPORT
    ]);
    const isAuthorizedToSkid = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_SKID
    ]);
    const isAuthorizedToTeleObject = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_TELEOBJECTS
    ]);
    const isAuthorizedToAudit = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_AUDIT
    ]);

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
                <List>
                    {/*<Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.REPORTS_ALARMS}>*/}
                        <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToAlarms ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Alarms" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_PERFORMANCE_SUMMARY}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToPerformanceSummary ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Performance Summary" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_POWER_PLANTS}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToPowerPlant ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Power Plants" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_EQUIPMENT_CLASS}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToEquipmentClass ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Equipment Classes" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_EQUIPMENT}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToEquipment ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Equipments" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_SKID}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToSkid ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Skids" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToTeleObject ? SystemRoutes.REPORTS_TELEOBJECTS : '#'}>
                        <ListItem disablePadding>
                            <ListItemButton disabled={!isAuthorizedToTeleObject}>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Relatório de Telemedidas" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_AUDIT}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to={isAuthorizedToAudit ? "/reports" : "#"}>
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Audit" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={isAuthorizedToManagementReport ? SystemRoutes.REPORTS_MANAGEMENT_REPORT : '#'}>
                        <ListItem disablePadding>
                            <ListItemButton disabled={!isAuthorizedToManagementReport}>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Relatório de Gestão" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </List>
            </nav>
        </Box>

    );
}