import React, { FunctionComponent } from "react";
import {AChartX} from "@atiautomacao/ati-ui-library"
import {Typography, useTheme} from "@mui/material";
import {AChartXProps} from "@atiautomacao/ati-ui-library/dist/components/AChartX/AChartX";

export interface GaugeSeriesOption {
    unity: string;
    value: number;
    intervalsTicks: Array<any>;
    max: number;
}

const Gauge: FunctionComponent<{series: GaugeSeriesOption, title: string}> = ({series, title}) => {
    const theme = useTheme();
    const option: AChartXProps['option'] = {
        yAxis:{show: false},
        xAxis:{show: false},
        series: [series].map(el => ({
            type: 'gauge',
            z: 10,
            splitNumber: el.max < 20 ?  el.max/2 : 10,
            axisLine: {
                lineStyle: {
                    width: 16,
                    color: el.intervalsTicks,
                }
            },
            pointer: {
                icons: "vector",
                itemStyle: {
                    color: 'inherit'
                },

            },
            axisTick: {
                distance: -30,
                length: 8,
                lineStyle: {
                    color: '#fff',
                    width: 2
                }
            },
            grid: {
                show: true,
                containLabel: true,
            },
            splitLine: {
                distance: -30,
                length: 30,
                lineStyle: {
                    color: '#fff',
                    width: 4
                }
            },
            axisLabel: {
                color: 'inherit',
                distance: 20,
                fontSize: 8
            },
            detail: {
                valueAnimation: true,
                formatter: (value: any) => value + ' ' + el.unity,
                fontSize: 8,
                color: '#8d8585'
            },
            data:
                [{value: el.value,}],
            min: 0,
            max: el.max,
        }))
    }

    return (
        <>
            <AChartX
                style={{cursor: "pointer"}}
                option={option}
                height={200}
                loading={false}
                theme={theme.palette.mode}
            />
            <Typography variant="h1" textAlign={"center"} fontSize={12} style={{marginTop: '-30px'}}>
                {title}
            </Typography>
        </>
    )
}

export default Gauge;