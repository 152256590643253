import {useQuery} from "react-query";
import axios from "axios";

const apiUrl = "api/command-history";



const fetchCommandHistory = async () => {
    try {
        return await axios
            .get(apiUrl)
            .then((res) => res.data);
    } catch (error : any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error("Error fetching data: " + error.message));
    }
};

export const CommandHistory = (isAuthenticated:boolean, isAuthorizedToShowGrid: boolean) => {
    return useQuery("data", fetchCommandHistory, {
        refetchInterval: 10000 ,// Define o intervalo de refetch para 30 segundos
        enabled: isAuthenticated && isAuthorizedToShowGrid
    });
};