import {ADataGridColumn} from "@atiautomacao/ati-ui-library";

export interface ColumnType {
    id: 'name' | 'id' | 'value' | 'status' | 'checkbox' | 'action';
    label: string;
    minWidth?: number;
    align?: 'right' | 'left';
    format?: (value: number) => string;
}
export const columnsValue: readonly ColumnType[] = [
    {
        id: 'checkbox',
        label: '',
        align: 'left',
        minWidth: 14,
    },
    {
        id: 'name',
        label: 'descrição',
        align: 'left',
        minWidth: 4
    },
    {
        id: 'value',
        label: 'valor',
        align: 'left',
        minWidth: 4
    },
]

export const columnsValueAndAction: readonly ColumnType[] = [
    {
        id: 'checkbox',
        label: '',
        align: 'left',
        minWidth: 14,
    },
    {
        id: 'name',
        label: 'descrição',
        align: 'left',
        minWidth: 4
    },
    {
        id: 'value',
        label: 'valor',
        align: 'left',
        minWidth: 4
    },
    {
        id: 'action',
        label: 'enviar',
        align: 'left',
        minWidth: 4
    },
]

export const columnsStatus: readonly ColumnType[] = [
    {
        id: 'checkbox',
        label: '',
        align: 'left',
        minWidth: 14,
    },
    {
        id: 'name',
        label: 'descrição',
        align: 'left',
        minWidth: 4
    },
    {
        id: 'status',
        label: 'status',
        align: 'left',
        minWidth: 4
    },
]

export const telesinalColumns = [
    {
        name: 'teleObjectName',
        label: 'descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'status',
        label: 'status',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[]


export const telemetryColumns = [
    {
        name: 'teleObjectName',
        label: 'descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'value',
        label: 'valor',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[]

export const telecommandColumns = [
    {
        name: 'teleObjectName',
        label: 'descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'value',
        label: 'valor',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[]