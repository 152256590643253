import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {AppBar, Box, useMediaQuery, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { useLocation} from "react-router-dom";
import {
    isDashboardPowerPlantPage,
    isDashboardPowerPlantSKidPage,
    SystemRoutes
} from "../../../Utils/RouteUtils";
import {useAppSelector} from "../../../Config/Hooks";
import DashboardHeaderMenuMediumLargeScreens from "./DashboardHeaderMenuMediumLargeScreens";
import DashboardHeaderMenuMobileScreens from "./DashboardHeaderMenuMobileScreens";

const style = {
    linkItem: {
    },
    selectedLinkItem: {
        textDecoration: 'none',
    }
}


const getLayoutUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_LAYOUT;
}

const getChartUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_CHARTS;
}

const getAlarmsUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_ALARMS;
}

const getTrackersUrl = (): string => {
    return SystemRoutes.DASHBOARD_POWER_PLANT_TRACKERS;
}
const getInverterUrl = (): string => {
    return SystemRoutes.DASHBOARD_POWER_PLANT_INVERTER;
}

const SGDDashboardHeaderMenu = (): ReactElement => {

    const theme = useTheme();
    const extraSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
    const smallScreen = useMediaQuery(theme.breakpoints.only("sm"));
    const mediumScreen = useMediaQuery(theme.breakpoints.only("md"));
    const largeUpScreen = useMediaQuery(theme.breakpoints.only("lg"));
    const extraLargeUpScreen = useMediaQuery(theme.breakpoints.only("xl"));

    const location = useLocation();
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const headerMenu = useAppSelector((state: any) => state.dashboardMenu.header);

    const [layoutUrl, setLayoutUrl] = useState(getLayoutUrl());
    const [chartUrl, setChartUrl] = useState(getChartUrl());
    const [alarmsUrl,setAlarmsUrl] = useState(getAlarmsUrl());
    const [trackersUrl, setTrackersUrl] = useState(getTrackersUrl());
    const [invertersUrl, setInvertersUrl] = useState(getInverterUrl());

    useEffect(()=> {
        setLayoutUrl(getLayoutUrl);
        setChartUrl(getChartUrl);
        setAlarmsUrl(getAlarmsUrl);
        setTrackersUrl(getTrackersUrl);
        setInvertersUrl(getInverterUrl);
    },[location.pathname])

    if (isAuthenticated && headerMenu.open ) return (
        <AppBar style={{top: 64}}>
            <Toolbar
                sx={{
                    marginLeft: {xs: "initial", md: isAuthenticated ? (
                            openSubMenu ? (
                                openMainMenu ? `${400}px` : `${80}px`
                            ) : (
                                openMainMenu ? `${80}px` : `${0}` // Set margin to 50 if submenu closed and main menu open
                            )
                        ) : 'initial'},
                    backgroundColor: theme.palette.background.default
                }}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex'}}} >
                    <DashboardHeaderMenuMediumLargeScreens />
                </Box>
            </Toolbar>
        </AppBar>
    );

    return <></>;
}

export default SGDDashboardHeaderMenu;
